import axios from "axios";

const state = () => ({
    rates: [],
    rows: 1,
    perPage: 10,
    meta: {},
});

const getters = {
    rates: (state) => {
        return state.rates;
    },
    rows: (state) => {
        return state.rows;
    },
    perPage: (state) => {
        return state.perPage;
    },
    meta: state => {
        return state.meta;
    },
};

// actions
const actions = {
    getRates({commit, dispatch}) {
        dispatch("Settings/loading", true, {root: true});
        try {
            return axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/rates`)
                .then((res) => {
                    commit("SET_RATES", res.data.data);
                    commit(
                        "SET_ROWS",
                        Math.ceil(res.data.meta.total / res.data.meta.per_page)
                    );
                    commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
                    commit('setMeta', res.data.meta)
                    dispatch("Settings/loading", false, {root: true});
                }).catch(() => {
                    dispatch("Settings/loading", false, {root: true});
                });
        } catch (err) {
            dispatch("Settings/loading", false, {root: true});
        }
    },

    store({commit}, data) {
        return axios.post(`${process.env.VUE_APP_API_BASE_URL}/admin/rates`, data)
            .then((res) => {
                commit("ADD_RATE", res.data.data);
                return res;
            });
    },

    getRate({commit}, id) {
        return axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/rates/${id}`)
            .then((res) => {
                return res;
            });
    },
    update({commit}, data) {
        return axios.put(`${process.env.VUE_APP_API_BASE_URL}/admin/rates/${data.id}`, data)
            .then((res) => {
                return res;
            });
    },
    delete({commit}, id) {
        return axios.delete(`${process.env.VUE_APP_API_BASE_URL}/admin/rates/${id}`)
            .then((res) => {
                return res;
            });
    },
};

// mutations
const mutations = {
    SET_RATES(state, rates) {
        state.rates = rates;
    },
    ADD_RATE(state, data) {
        state.rates.unshift(data);
    },
    SET_ROWS(state, rows) {
        state.rows = rows;
    },
    SET_PER_PAGE(state, perPage) {
        state.perPage = perPage;
    },
    setMeta(state, meta) {
        state.meta = meta;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
