import axios from 'axios';

const state = {
    roles: [],
    role: {},
    rows: 1,
    perPage: 10,
    meta: {},
};

const getters = {
    roles: state =>{
        return state.roles;
    },
    role: state =>{
        return state.role;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
    meta: state =>{
        return state.meta;
    }
};

const actions = {
    getRoles({commit, dispatch}, filter){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/roles?page=${filter.currentPage}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`)
                .then((res) => {
                    commit('setRoles', res.data.data);
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('SET_META', res.data.meta)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }
    },
    getRole({commit},roleId){
        try {
            axios
                .get(`/admin/roles/${roleId}`)
                .then((res) => {
                    commit('setRole', res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }

    },
    storeRole({commit}, payload){
        return axios
            .post(`/admin/roles`, payload)
            .then((res) => {
                commit('updateRolesList', res.data.data);
            });
    },

    updateRole({commit}, role){
        return axios
            .put(`/admin/roles/${role.id}`, role)
            .then((res) => {
                commit('updateEditedRole', res.data.data);
            });
    },

};

const mutations = {
    setRoles(state, roles){
        state.roles = roles;
    },
    setRole(state, role){
        state.role = role;
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta){
        state.meta = meta;
    },
    updateRolesList(state, role){
        state.roles.unshift(role);
    },
    updateEditedRole(state, role) {
        state.roles.map((item,index)=>{
            if(item.id == role.id){
                state.roles[index] = role;
            }
        });
    }
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
