var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card p-4"},[_c('SearchAndActions',{attrs:{"show-date":false,"show-single-date":true,"show-import":false,"action-url":"/admin/meter-consumers-list","download-file-name":"meter-consumers","show-today":""},on:{"search":_vm.onSearch,"date":_vm.onDate}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('DataTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Header',{attrs:{"sort":false,"title":"Sl"}}),_c('Header',{attrs:{"sort":false,"title":"Account No"}}),_c('Header',{attrs:{"sort":false,"title":"Meter No"}}),_c('Header',{attrs:{"sort":false,"title":"Present Meter Reading"}}),_c('Header',{attrs:{"sort":false,"title":"Present Reading Date"}}),(
                        _vm.checkUsrPermissions([
                          'Meter_all',
                          'Meter_consumption'
                        ])
                      )?_c('Header',{attrs:{"sort":false,"title":"Actions"}}):_vm._e()]},proxy:true}])},_vm._l((_vm.tableData),function(data,index){return _c('tr',{key:data.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(_vm.pagination.from + index)+" ")]),_c('td',[_c('router-link',{attrs:{"to":("/consumers/" + (data.customer_account.id))}},[_vm._v(" "+_vm._s(data.customer_account.account_number)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":'/meters/' + data.id}},[_vm._v(_vm._s(data.meter_number))])],1),_c('td',[_vm._v(" "+_vm._s(data.meter_current_consumption.water_unit)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(data.meter_current_consumption.reading_date_time))+" ")]),(
                        _vm.checkUsrPermissions([
                          'Meter_all',
                          'Meter_consumption'
                        ])
                      )?_c('td',[_c('ActionsButton',[_c('router-link',{attrs:{"to":'/meters/' + data.id + '/consumptions'}},[_vm._v(" Details Consumption ")])],1)],1):_vm._e()])}),0),(_vm.tableData.length)?_c('div',{staticClass:"mt-5"},[_c('TablePagination',{attrs:{"pagination":_vm.pagination},on:{"pagechanged":_vm.onPageChange,"pagesizechanged":_vm.onPageSizeChanged}})],1):_c('div',[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" No data found ")])])],1)])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('h6',{staticClass:"text-uppercase font-weight-bold text-dark"},[_vm._v("Meter Consumptions")])])}]

export { render, staticRenderFns }