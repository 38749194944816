<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";

export default {
  name: "All History",
  components: {TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "All History",
        path: "/all-history",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/history-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">All History</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/history-list"
              download-file-name="all-history"
          >
            <el-button
                type="primary"
                @click="$router.push({name: 'HistoryCreate'})"
                size="small"
                class="ml-2"
                icon="el-icon-edit"
            >
              Consumer Update
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="account_number" :ordering="sort" @sortBy="sortBy('account_number')" title="Details"/>
                  <Header active="changed_column" :ordering="sort" @sortBy="sortBy('changed_column')" title="Change Column"/>
                  <Header active="changed_value_from" :ordering="sort" @sortBy="sortBy('changed_value_from')" title="Old Value"/>
                  <Header active="changed_value_to" :ordering="sort" @sortBy="sortBy('changed_value_to')" title="New Value"/>
                  <Header active="modified_by" :ordering="sort" @sortBy="sortBy('modified_by')" title="Modify By"/>
                  <Header active="ip_address" :ordering="sort" @sortBy="sortBy('ip_address')" title="IP Address"/>
                  <Header active="action_date" :ordering="sort" @sortBy="sortBy('action_date')" title="Modified At"/>
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    <div style="cursor: pointer" @click.prevent="$router.push(`/consumers/${data.historyable_id}`)" v-if="data.historyable_type === 'App\\Models\\CustomerAccount'">
                      Account No: {{ data.account_number }} <br>
                      Meter No: {{ data.meter_number }} <br>
                      Consumer Name: {{ data.consumer_name }} <br>
                    </div>
                    <div style="cursor: pointer" @click.prevent="$router.push(`/meters/${data.historyable_id}`)" v-if="data.historyable_type === 'App\\Models\\Meter'">
<!--                      Account No: {{ data.account_number }} <br>-->
<!--                      Meter No: {{ data.meter_number }} <br>-->
                      Consumer Name: {{ data.consumer_name }} <br>
                    </div>
                    <div v-if="data.historyable_type !== 'App\\Models\\Meter' && data.historyable_type !== 'App\\Models\\CustomerAccount'">{{ data.historyable_type }}</div>
                  </td>
                  <td>
                    {{ data.changed_column }}
                  </td>
                  <td>
                    {{ data.changed_value_from }}
                  </td>
                  <td>
                    {{ data.changed_value_to }}
                  </td>
                  <td>
                    {{ data.modified_by }}
                  </td>
                  <td>
                    {{ data.ip_address }}
                  </td>
                  <td>
                    {{ data.modified_at }}
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
