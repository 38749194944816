import axios from 'axios';

const state = {
    rocodes: [],
    rocodeLists: [],
    rows: 1,
    meta: {},
    perPage: 10,
};

const getters = {
    rocodes: state =>{
        return state.rocodes;
    },
    rocodeLists: state =>{
        return state.rocodeLists;
    },
    rows: state => {
        return state.rows;
    },
    meta: state => {
        return state.meta;
    },
    perPage: state => {
        return state.perPage;
    },
};

const actions = {
    getROCodes({commit, dispatch}, filter){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/rocodes?page=${filter.currentPage}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`)
                .then((res) => {

                    commit('setROCodes', res.data.data);
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setMeta', res.data.meta)
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    getROCodeLists({commit, dispatch}, currentPage){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/rocodes/lists?page=${currentPage  ? currentPage : '1'}`)
                .then((res) => {

                    commit('setROCodeLists', res.data.data);
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    storeROCode({commit}, payload){
        return axios
            .post(`/admin/rocodes`, payload)
            .then((res) => {
                commit('updateROCodesList', res.data.data);
            });
    },

    updateROCode({commit}, user){
        return axios
            .put(`/admin/rocodes/${user.id}`, user)
            .then((res) => {
                commit('updateEditedROCode', res.data.data);
            });
    },
    destroy({ commit }, id) {
        return axios.delete(`/admin/rocodes/${id}`).then((res) => {
            commit("DELETE_NOTICE", id);
            return res;
        });
    },
};

const mutations = {
    setROCodes(state, rocodes){
        state.rocodes = rocodes;
    },
    setROCodeLists(state, rocodes){
        state.rocodeLists = rocodes;
    },
    updateROCodesList(state, user){
        state.rocodes.unshift(user);
    },
    updateEditedROCode(state, user){
        state.rocodes.map((item,index)=>{
            if(item.id == user.id){
                state.rocodes[index] = user;
            }
        });
    },
    DELETE_NOTICE(state, id) {
        const i = state.rocodes.map((item) => item.id).indexOf(id);
        state.rocodes.splice(i, 1);
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setMeta(state, meta) {
        state.meta = meta;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
