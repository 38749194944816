import axios from 'axios';

const state = {
    meterSizes: [],
    constants: [],
};

const getters = {
    meterSizes: state =>{
        return state.meterSizes;
    },
    constants: state =>{
        return state.constants;
    }
};

const actions = {
    getMeterSizes({commit}, payload){
        return axios
            .get(`/admin/constant/${payload}`)
            .then((res) => {
                commit('SET_METER_SIZES', res.data.data);
            });
    },

    getConstants({commit}, payload){
        return axios
            .get(`/admin/fetch-constants?types=${payload}`)
            .then((res) => {
                commit('SET_CONSTANTS', res.data.data);
            });
    }
};

const mutations = {
    SET_METER_SIZES(state, meterSizes){
        state.meterSizes = meterSizes;
    },
    SET_CONSTANTS(state, constants){
        state.constants = constants;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
