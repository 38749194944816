<script>
import _ from "lodash";
import axios from "axios";

export default {
  name: "SearchAndActions",
  props: {
    actionUrl: {
      type: String,
      default: ''
    },
    downloadFileName: {
      type: String,
      default: 'download'
    },
    showImport: {
      type: Boolean,
      default: true
    },
    showExcel: {
      type: Boolean,
      default: true
    },
    showPdf: {
      type: Boolean,
      default: true
    },
    showPrint: {
      type: Boolean,
      default: true
    },
    showAddNew: {
      type: Boolean,
      default: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showSingleDate: {
      type: Boolean,
      default: false
    },
    showToday: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      search: null,
      loading: false,
      date: this.showToday ? new Date() : null,
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },

  methods: {
    print() {
      window.print();
    },

    handleCommand(command) {
      if (command === 'print') this.print();
      else if (command === 'excel') this.onExcel();
      else if (command === 'pdf') this.onPdf();
      else
        this.$emit(command);
    },

    startLoading() {
      this.loading = true;
    },

    onExcel() {
      this.exportFile('xlsx');
    },

    onPdf() {
      this.exportFile('pdf');
    },

    exportFile(type) {
      axios.get(`${this.actionUrl}?export=${type}`, {responseType: 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.downloadFileName}.${type}`);
            document.body.appendChild(link);
            link.click();
          })
    }
  },
  watch: {
    search: _.debounce(function (e) {
      this.$emit("search", e);
      this.loading = false;
    }, 500),

    date: {
      handler: function (val) {
        this.$emit("date", val);
      },
      deep: true
    }
  }
}
</script>

<template>
  <div class="row mb-3">
    <div class="col-md-3">
      <el-input
          placeholder="Type something"
          :prefix-icon="loading ? 'el-icon-loading' : 'el-icon-search'"
          v-model="search"
          @input="startLoading"
          size="small"
          clearable
      />
    </div>
    <div class="col-md-4">
      <el-date-picker
          v-if="showDate"
          v-model="date"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="-"
          start-placeholder="Start date"
          end-placeholder="End date"
          :picker-options="pickerOptions"
          size="small"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
      />

      <el-date-picker
          v-if="showSingleDate"
          v-model="date"
          type="date"
          placeholder="Select a day"
          size="small"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
      />
    </div>
    <div class="col-md-5 text-right d-flex align-items-center justify-content-end">
      <el-dropdown @command="handleCommand">
        <div class="el-dropdown-link">
          Actions<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
              v-if="showImport"
              icon="el-icon-upload"
              command="import"
          >
            Import
          </el-dropdown-item>
          <el-dropdown-item
              v-if="showExcel"
              icon="el-icon-download"
              command="excel"
          >
            Export Excel
          </el-dropdown-item>
          <el-dropdown-item
              v-if="showPdf"
              icon="el-icon-tickets"
              command="pdf"
          >
            Export PDF
          </el-dropdown-item>
          <el-dropdown-item
              v-if="showPrint"
              icon="el-icon-printer"
              command="print"
          >
            Print
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <slot/>
    </div>

    <slot name="filter"/>
  </div>
</template>

<style lang="scss">
.el-input {
  .el-input__clear {
    color: red !important;
    font-size: 16px;

    &:hover {
      color: red !important;
    }
  }
}

.el-date-editor {
  .el-range__close-icon {
    color: red !important;
    font-size: 16px;
  }
}
</style>
