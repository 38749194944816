import axios from "axios";
import { saveAs } from "file-saver";

const state = () => ({
  feedbacks: [],
  rows: 1,
  perPage: 10,
  meta: {},
});

const getters = {
  feedbacks: (state) => {
    return state.feedbacks;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  meta: (state) => {
    return state.meta;
  },
};

// actions
const actions = {
  getFeedbacks({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `${
            process.env.VUE_APP_API_BASE_URL
          }/admin/feedbacks?${ new URLSearchParams(filter).toString() }`
        )
        .then((res) => {
          commit("SET_FEEDBACKS", res.data.data);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },
  exportFeedbacks({ dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });
    axios
      .get(
        `${process.env.VUE_APP_API_BASE_URL}/admin/feedbacks/export?${ new URLSearchParams(filter).toString() }`,
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        dispatch("Settings/loading", false, { root: true });
        let blob = new Blob([res.data], {
          type: "application/xlsx;charset=utf-8",
        });
        var number = Math.ceil(Math.random() * 1000);
        var filename = number + "_customer_feedbacks.xlsx";
        saveAs(blob, filename);
      })
      .catch(() => {
        dispatch("Settings/loading", false, { root: true });
        this.$toastr("error", "Something went wrong", "Error!");
      });
  },
};

// mutations
const mutations = {
  SET_FEEDBACKS(state, feedbacks) {
    state.feedbacks = feedbacks;
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
