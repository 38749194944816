import axios from "axios";

const state = () => ({
  settlements: [],
  settlement: {attachments:[], customer_account: {}},
  rows: 1,
  perPage: 10,
});

const getters = {
  settlements: (state) => {
    return state.settlements;
  },
  settlement: (state) => {
    return state.settlement;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
};

// actions
const actions = {
  getSettlements({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/admin/bill-settlements?status=${
            filter && filter.status ? filter.status : ""
          }&page=${filter && filter.currentPage ? filter.currentPage : ""}`
        )
        .then((res) => {
          commit("SET_BILL_SETTLEMENT", res.data.data);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          dispatch("Settings/loading", false, { root: true });
        })
        .catch(() => {
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      dispatch("Settings/loading", false, { root: true });
    }
  },

  store({ commit }, data) {
    return axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/admin/bill-settlements`,
        data,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      )
      .then((res) => {
        commit("ADD_NEW_SETTLEMENT", res.data.data);
        return res;
      });
  },

  update({ commit }, data) {
    return axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/admin/bill-settlements/${data.get('id')}?_method=put`,
        data,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      )
      .then((res) => {
        commit("UPDATE_BILL_SETTLEMENT", res.data.data);
        return res;
      });
  },

  getSettlementInfo({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });
    try {
      return axios
        .get(`/admin/bill-settlements/${id}`)
        .then((res) => {
          commit("SET_BILL_SETTLEMENT_DETAIL", res.data.data);
          dispatch("Settings/loading", false, { root: true });
          return res;
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },

  destroy({ commit }, id) {
    try {
      return axios.delete(`/admin/bill-settlements/${id}`).then((res) => {
        commit("DELETE_CUSTOMER_APP", id);
        return res;
      });
    } catch (err) {
      console.log(err);
    }
  },

  deleteAttachment({ commit }, id) {
    try {
      return axios.delete(`/admin/attachments/${id}`).then((res) => {
        commit("DELETE_CUSTOMER_APP_ATTACHMENT", id);
        return res;
      });
    } catch (err) {
      console.log(err);
    }
  },

  verifySettlement({ commit }, data) {
    return axios
      .post(`/admin/bill-settlements/${data.id}/verify`, data)
      .then((res) => {
        commit("UPDATE_BILL_SETTLEMENT_STATUS", res.data.data);
      });
  },
};

// mutations
const mutations = {
  SET_BILL_SETTLEMENT(state, settlements) {
    state.settlements = settlements;
  },
  ADD_NEW_SETTLEMENT(state, data) {
    state.settlements.unshift(data);
  },
  SET_BILL_SETTLEMENT_DETAIL(state, data) {
    state.settlement = data;
  },
  UPDATE_BILL_SETTLEMENT(state, data) {
    const i = state.settlements.map((item) => item.id).indexOf(data.id);
    state.settlements[i] = data;
  },
  UPDATE_BILL_SETTLEMENT_STATUS(state, settlement) {
    state.settlement = settlement;
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
  DELETE_CUSTOMER_APP(state, id) {
    const i = state.settlements.map((item) => item.id).indexOf(id);
    state.settlements.splice(i, 1);
  },
  DELETE_CUSTOMER_APP_ATTACHMENT(state, id) {
    const i = state.settlement.attachments.map((item) => item.id).indexOf(id);
    state.settlement.attachments.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
