import axios from 'axios';

const state = {
    users: [],
    usersList: [],
    supervisorUsersList: [],
    teamLeadUsersList: [],
    rows: 1,
    perPage: 10,
    meta: {},
};

const getters = {
    users: state =>{
        return state.users;
    },
    usersList: state =>{
        return state.usersList;
    },
    supervisorUsersList: state =>{
        return state.supervisorUsersList;
    },
    teamLeadUsersList: state =>{
        return state.teamLeadUsersList;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
    meta: state => {
        return state.meta;
    },
};

const actions = {
    getUsers({commit, dispatch}, filter){
        dispatch('Settings/loading', true,{root:true});


        try {
            axios
                .get(`/admin/users?page=${filter.currentPage}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`)
                .then((res) => {

                    commit('setUsers', res.data.data);
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('SET_META', res.data.meta)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },

    // This action is for users select dropdown
    getUsersList({commit, dispatch}, role=''){
        dispatch('Settings/loading', true,{root:true});
        try {
            return axios
                .get(`/admin/users/list?role=${role}`)
                .then((res) => {
                    if (role=='supervisor'){
                        commit('SET_SUPERVISOR_USERS_LIST', res.data.data);
                    }else if(role=='team_lead'){
                        commit('SET_TEAM_LEAD_USERS_LIST', res.data.data);
                    }else {
                        commit('SET_USERS_LIST', res.data.data);
                    }
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            dispatch('Settings/loading', false,{root:true});
        }

    },
    storeUser({commit}, payload){
        return axios
            .post(`/admin/users`, payload)
            .then((res) => {
                commit('updateUsersList', res.data.data);
            });
    },

    updateUser({commit}, user){
        return axios
            .put(`/admin/users/${user.id}`, user)
            .then((res) => {
                commit('updateEditedUser', res.data.data);
            });
    }
};

const mutations = {
    setUsers(state, users){
        state.users = users;
    },
    SET_USERS_LIST(state, usersList){
        state.usersList = usersList;
    },
    SET_SUPERVISOR_USERS_LIST(state, supervisorUsersList){
        state.supervisorUsersList = supervisorUsersList;
    },
    SET_TEAM_LEAD_USERS_LIST(state, teamLeadUsersList){
        state.teamLeadUsersList = teamLeadUsersList;
    },
    updateUsersList(state, user){
        state.users.unshift(user);
    },
    updateEditedUser(state, user){
        state.users.map((item,index)=>{
            if(item.id == user.id){
                state.users[index] = user;
            }
        });
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
