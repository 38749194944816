import axios from "axios";

const state = () => ({
    applications: [],
    application: {attachments:[]},
    rows: 1,
    perPage: 10,
});

const getters = {
    applications: (state) => {
        return state.applications;
    },
    application: (state) => {
        return state.application;
    },
    rows: (state) => {
        return state.rows;
    },
    perPage: (state) => {
        return state.perPage;
    },
};

// actions
const actions = {
    getApplications({ commit, dispatch }, filter) {
        dispatch("Settings/loading", true, { root: true });
        try {
            axios
                .get(
                    `${process.env.VUE_APP_API_BASE_URL}/admin/customer-applications?name=${
                        filter && filter.name ? filter.name : ""
                    }&status=${
                        filter && filter.status ? filter.status : ""
                    }&ward_number=${
                        filter && filter.ward_number ? filter.ward_number : ""
                    }&mobile_number=${
                        filter && filter.mobile_number ? filter.mobile_number : ""
                    }&page=${filter && filter.currentPage ? filter.currentPage : ""}`
                )
                .then((res) => {
                    commit("SET_CUSTOMER_APP", res.data.data);
                    commit(
                        "SET_ROWS",
                        Math.ceil(res.data.meta.total / res.data.meta.per_page)
                    );
                    commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
                    dispatch("Settings/loading", false, { root: true });
                })
                .catch(() => {
                    dispatch("Settings/loading", false, { root: true });
                });
        } catch (err) {
            dispatch("Settings/loading", false, { root: true });
        }
    },

    store({ commit }, data) {
        return axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/admin/customer-applications`,
                data,
                {
                    headers: { "content-type": "multipart/form-data" },
                }
            )
            .then((res) => {
                commit("ADD_CUSTOMER_APP", res.data.data);
                return res;
            });
    },

    update({ commit }, data) {
        return axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/admin/customer-applications/${data.get('id')}?_method=put`,
                data,
                {
                    headers: { "content-type": "multipart/form-data" },
                }
            )
            .then((res) => {
                commit("UPDATE_CUSTOMER_APP", res.data.data);
                return res;
            });
    },

    getApplicationInfo({ commit, dispatch }, id) {
        dispatch("Settings/loading", true, { root: true });
        try {
            return axios
                .get(`/admin/customer-applications/${id}`)
                .then((res) => {
                    commit("SET_CUSTOMER_APP_DETAIL", res.data.data);
                    dispatch("Settings/loading", false, { root: true });
                    return res;
                })
                .catch((err) => {
                    console.log(err);
                    dispatch("Settings/loading", false, { root: true });
                });
        } catch (err) {
            console.log(err);
        }
    },

    destroy({ commit }, id) {
        try {
            return axios.delete(`/admin/customer-applications/${id}`).then((res) => {
                commit("DELETE_CUSTOMER_APP", id);
                return res;
            });
        } catch (err) {
            console.log(err);
        }
    },

    deleteAttachment({ commit }, id) {
        try {
            return axios.delete(`/admin/attachments/${id}`).then((res) => {
                commit("DELETE_CUSTOMER_APP_ATTACHMENT", id);
                return res;
            });
        } catch (err) {
            console.log(err);
        }
    },

    verifyApplication({ commit }, data) {
        return axios
            .post(`/admin/customer-applications/${data.id}/verify`, data)
            .then((res) => {
                commit("UPDATE_CUSTOMER_APP_STATUS", res.data.data);
            });
    },
};

// mutations
const mutations = {
    SET_CUSTOMER_APP(state, applications) {
        state.applications = applications;
    },
    ADD_CUSTOMER_APP(state, data) {
        state.applications.unshift(data);
    },
    SET_CUSTOMER_APP_DETAIL(state, data) {
        state.application = data;
    },
    UPDATE_CUSTOMER_APP(state, data) {
        const i = state.applications.map((item) => item.id).indexOf(data.id);
        state.applications[i] = data;
    },
    UPDATE_CUSTOMER_APP_STATUS(state, application) {
        state.application = application;
    },
    SET_ROWS(state, rows) {
        state.rows = rows;
    },
    SET_PER_PAGE(state, perPage) {
        state.perPage = perPage;
    },
    DELETE_CUSTOMER_APP(state, id) {
        const i = state.applications.map((item) => item.id).indexOf(id);
        state.applications.splice(i, 1);
    },
    DELETE_CUSTOMER_APP_ATTACHMENT(state, id) {
        const i = state.application.attachments.map((item) => item.id).indexOf(id);
        state.application.attachments.splice(i, 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
