import Vue from 'vue'
import moment from "moment";
Vue.filter("dateFormat", function (date) {
    return date != null ? moment(String(date)).format(process.env.VUE_APP_DATE_FORMAT) : ''
});
//dateTimeFormat
Vue.filter("dateTimeFormat", function (date_time) {
    if (!date_time){
        return '';
    }
    return moment(String(date_time)).format(process.env.VUE_APP_DATE_TIME_FORMAT)
});
//timeFormat
Vue.filter("timeFormat", function (time) {
    return time != null ? moment(time, "HH:mm:ss").format(process.env.VUE_APP_TIME_FORMAT) : ''
});
