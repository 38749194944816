import Vue from 'vue'
import Router from 'vue-router'
import MainRoutes from "@/router/MainRoutes";
import SettingRoutes from "@/router/SettingRoutes";
import InformationRoutes from "@/router/InformationRoutes";

Vue.use(Router)

const routes = [
    ...MainRoutes,
    ...SettingRoutes,
    ...InformationRoutes
];

export default new Router({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: routes
})

