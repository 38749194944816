import TheContainer from "@/containers/TheContainer.vue";

const routes = [
    {
        path: '',
        component: TheContainer,
        meta: {
            auth: true
        },
        children: [
            {
                path: '/system-settings',
                name: 'SystemSettings',
                component: () => import('@/views/Setting.vue')
            },
            {
                path: '/events',
                name: 'Events',
                component: () => import('@/views/Events.vue')
            },
            {
                path: '/notices',
                name: 'Notices',
                component: () => import('@/views/notices/Notices.vue')
            },
            {
                path: '/notices/:id',
                name: 'NoticeView',
                component: () => import('@/views/notices/NoticeView.vue')
            },
            {
                path: '/users',
                name: 'Users',
                component: () => import('@/views/Users.vue')
            },
            {
                path: '/users/profile',
                meta: {
                    label: 'User Details'
                },
                name: 'Profile',
                component: () => import('@/components/profile/Profile.vue')
            },
            {
                path: '/roles',
                name: 'Roles',
                component: () => import('@/views/Roles.vue')
            }
        ]
    }
];

export default routes;
