import axios from "axios";

const state = () => ({
  consumers: [],
  consumerAccount: { first_name: "", last_name: "" },
  meters: [],
  rows: 1,
  perPage: 10,
  meta: {},
  isLoading: false,
  consumer: {},
  totalPage: 1,
  pages: 1,
});

const getters = {
  consumers(state, getters, rootState, rootGetters) {
    return state.consumers;
  },
  totalPage(state) {
      return state.totalPage;
  },
  consumerAccount: (state) => {
    return state.consumerAccount;
  },
  meters: (state) => {
    return state.meters;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  meta: state => {
    return state.meta;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  consumer: (state) => {
    return state.consumer;
  },
};

// actions
const actions = {
  getLatestConsumers({ commit }) {
    return axios
        .get(
            `${process.env.VUE_APP_API_BASE_URL}/admin/consumers?limit=5`
        )
        .then((res) => {
          commit("SET_CONSUMER", res.data.data);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });

  },
  getConsumers({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
          .get(
              `${process.env.VUE_APP_API_BASE_URL}/admin/consumers?column=${filter && filter.column ? filter.column : ''}&keyword=${filter && filter.keyword ? filter.keyword : ''}&page=${filter && filter.currentPage ? filter.currentPage : ''}&offset=${filter && filter.offset ? filter.offset : ''}&limit=${filter && filter.limit ? filter.limit : ''}&sortBy=${filter && filter.sortBy ? filter.sortBy : ''}&sortOrder=${filter && filter.sortOrder ? filter.sortOrder : ''}`
          )
        .then((res) => {
          commit("SET_CONSUMER", res.data.data);
          commit("SET_TOTAL_PAGE", res.data.meta.total);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          commit('setMeta', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  store({ commit }, data) {
    return axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/admin/consumers`, data)
        .then((res) => {
          if (data.consumer_id){
            commit("ADD_CONSUMER", res.data.data);
          }
        });
  },

  update({ commit }, data) {
    return axios
      .put(
        `${process.env.VUE_APP_API_BASE_URL}/admin/consumers/${data.id}`,
        data
      )
      .then((res) => {
        commit("UPDATE_ACC", res.data.data);
      });
  },
  assignTeamToConsumer({ commit }, { team, consumerId }) {
    return axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/admin/consumers/${consumerId}/assign-teams`,
        team
      )
      .then((res) => {
        commit("SET_TASK_ASSIGNED", res.data.data);
      });
  },

  getConsumerById({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(`/admin/consumers/${id}`)
        .then((res) => {
          commit("setConsumerById", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },

  consumerAccountDetails({ commit, dispatch }, payload) {
    const { id, query } = payload;
    dispatch("Settings/loading", true, { root: true });
    commit('RESET_CUSTOMER');
    try {
      return axios
        .get(`/admin/consumers/${id}${query}`)
        .then((res) => {
          commit("CONSUMER_DETAILS", res.data.data);
          dispatch("Settings/loading", false, { root: true });
          return res;
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },

  getMetersByConsumerId({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`/admin/consumers/${id}/meters`)
        .then((res) => {
          commit("setMetersByConsumerId", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },
};

// mutations
const mutations = {
  SET_TOTAL_PAGE(state, totalPage) {
    state.totalPage = totalPage;
  },
  SET_CONSUMER(state, consumers) {
    state.consumers = consumers;
  },
  ADD_CONSUMER(state, data) {
    state.consumers.unshift(data);
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
  setMeta(state, meta) {
    state.meta = meta;
  },
  setConsumerById(state, consumer) {
    state.consumer = consumer;
  },
  setMetersByConsumerId(state, meters) {
    state.meters = meters;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  CONSUMER_DETAILS(state, consumer) {
    state.consumer = consumer;
  },
  RESET_CUSTOMER(state) {
    state.consumer = {};
  },
  UPDATE_ACC(state, consumerAccount) {
    state.consumers.map((item, index) => {
      if (item.id == consumerAccount.id) {
        state.consumers[index] = consumerAccount;
      }
    });
  },
  SET_TASK_ASSIGNED(state, consumerAccount) {
    state.consumers.map((item, index) => {
      if (item.id == consumerAccount.id) {
        state.consumers[index]['hasTask'] = true;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
