import axios from "axios";

const state = () => ({ 
  notifications: [],    
  rows: 1,
  perPage: 25,
  notificationCount: 0
});

const getters = {
  notifications: (state) => {
    return state.notifications;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  notificationCount: (state) => {
    return state.notificationCount;
  },
};

// actions
const actions = {
  getNotifications({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });    
      return axios
        .get(
          `/admin/user-notifications?page=${
            filter && filter.currentPage ? filter.currentPage : ""
          }`
        )
        .then((res) => {
          commit("SET_NOTIFICATIONS", res.data.data);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {          
          dispatch("Settings/loading", false, { root: true });
          return err;
        });    
  },

  markAllAsRead({ commit, dispatch }) {
    dispatch("Settings/loading", true, { root: true });    
      return axios
        .put(
          `/admin/user-notifications/mark-all-as-read`, {}
        )
        .then(() => {
          commit("MARK_ALL_READ", '');         
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {          
          dispatch("Settings/loading", false, { root: true });
          return err;
        });    
  },

  getNotificationCount({ commit }) {
    return axios
      .get(`/admin/users/unread-notifications-count`)
      .then((res) => {        
        commit('SET_NOTIFICATION_COUNT', res.data.data);
      })     
  },

  updateNotificationCount({ commit }, type) {
    if(type === 'add') {
      commit('SET_NOTIFICATION_COUNT_INCREMENT');
    } else {
      commit('SET_NOTIFICATION_COUNT_DECREMENT');
    }
  },

};

// mutations
const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  MARK_ALL_READ(state) {    
    state.notifications = _.map(state.notifications, (item) => {
      item.is_read = true;
      item.read_at = item.read_at?item.read_at:new Date();
      return item;
    });
    state.notificationCount = 0;
  },
  SET_NOTIFICATION_COUNT(state, count) {
    state.notificationCount = count;
  },
  SET_NOTIFICATION_COUNT_INCREMENT(state) {
    state.notificationCount += 1;
  },
  SET_NOTIFICATION_COUNT_DECREMENT(state) {
    state.notificationCount -= 1;
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
