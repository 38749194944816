import HistoryTracking from "@/components/History/HistoryTracking.vue";
import MeterConsumption from "@/views/MeterConsumption.vue";
import NewHistory from "@/views/NewHistory.vue";
const HistoryCreate = () => import('@/components/History/HistoryCreate.vue')

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Consumer = () => import('@/views/Consumer')
const ConsumerView = () => import('../components/consumers/ConsumersView')
const CreateConsumer = () => import('../components/consumers/CreateConsumer.vue')
const EditConsumer = () => import('../components/consumers/EditConsumer.vue')
const ViewConsumer = () => import('../components/consumers/ConsumersView.vue')
const ConsumerImport = () => import('../components/consumers/Import')

const Customer = () => import('@/views/Customer')
const CustomerAccount = () => import('@/views/CustomerAccount')

const CustomerApplication = () => import('@/views/applications/CustomerApplication')
const CustomerApplicationView = () => import('@/views/applications/CustomerApplicationView')
const CreateApplication = () => import('@/views/applications/CreateApplication')
const EditApplication = () => import('@/views/applications/EditApplication')

const CustomerView = () => import('../components/customers/CustomersView')
const CreateCustomer = () => import('../components/customers/CreateCustomer.vue')
const CustomerImport = () => import('../components/customers/CustomersImport')
const AssignMeterToCustomer = () => import('../components/customers/AssignMeterToCustomer')
const AssignMeterToCustomerAcc = () => import('../components/customer_accounts/AssignMeterToCustomerAcc')
const CustomerAccView = () => import('../components/customer_accounts/CustomersView')
const ScanAndStoreMeter = () => import('../components/meter/ScanAndStoreMeter')
const Meters = () => import('@/views/Meters')
const MeterErrorLog = () => import('@/views/MeterErrorsLog')
const Notification = () => import(/* webpackChunkName: "user-notification" */ '@/views/Notification')
const Tasks = () => import('@/views/Tasks')
const Payments = () => import('@/views/Payments')
const TaskView = () => import('../components/tasks/TaskView')
const MetersConsumptionsView = () => import('../components/meter/MetersConsumptionsView')

//generate
const GenerateReading = () => import('@/components/generate/reading/GenerateComp')
const ReadingList = () => import('@/components/generate/reading/ListComp')

const GenerateBill = () => import('@/components/generate/bill/GenerateComp')
const BillList = () => import('@/components/generate/bill/ListComp')
const Bills = () => import('@/views/Bill')
const BillGenerate = () => import('@/components/bill-generate/BillGenerateComp')
const BillCreate = () => import('@/components/Bill/CreateBillComp')
const ImportBill = () => import('@/components/Bill/ImportBill')
const SettlementList = () => import('@/views/settlements/SettlementList')
const CreateSettlement = () => import('@/views/settlements/CreateSettlement')
const EditSettlement = () => import('@/views/settlements/EditSettlement')
const SettlementView = () => import('@/views/settlements/SettlementView')
const PriceList = () => import('@/views/pricing/PriceList')
const CreatePrice = () => import('@/views/pricing/CreatePrice')
const MeterConsumptions = () => import(/* webpackChunkName: "metererrors" */'../components/meter/MeterConsumptions.vue')
const TblReadingView = () => import(/* webpackChunkName: "metererrors" */'../components/meter/TblReadingView.vue')
const MeterErrors = () => import(/* webpackChunkName: "metererrors" */'../components/meter/MeterErrors.vue')
const MeterView = () => import('../components/meter/MeterView.vue')
const WaterVehicleRequests = () => import('@/views/WaterVehicleRequest')
const InventoryMeters = () => import('@/views/Inventory')
const InventoryMetersImport = () => import('@/views/InventoryMetersImport')
const Reports = () => import('@/views/reports/ReportComponent.vue')
const BillingReports = () => import('@/views/reports/BillingReports/BillingReport.vue')
const MonthlyReports = () => import('@/views/reports/BillingReports/BillReportType/MonthlyReport.vue')
const QuarterlyBillingReport = () => import('@/views/reports/BillingReports/BillReportType/QuarterlyBillingReport.vue')
const HalfYearlyBillingReport = () => import('@/views/reports/BillingReports/BillReportType/HalfYearlyBillingReport.vue')
const YearlyBillingReport = () => import('@/views/reports/BillingReports/BillReportType/YearlyBillingReport.vue')
const LedgerBillingReports = () => import('@/views/reports/BillingReports/LedgerBillingReport.vue')
const ArrearsBillingReports = () => import('@/views/reports/BillingReports/ArrearsBillingReport.vue')
const BillingAnalytics = () => import('@/views/reports/BillingReports/Analytics/BillingAnalytics.vue')
const GrowthReport = () => import('@/views/reports/BillingReports/Growth/GrowthReport.vue')
const WardReport = () => import('@/views/reports/BillingReports/Customers/WardReport.vue')
const WaterVehicleRequestsView = () => import('../components/WaterVehicleRequest/WaterVehicleRequestsView')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page403 = () => import('@/views/pages/Page403')
const Page500 = () => import('@/views/pages/Page500')
// const Login = () => import('@/views/pages/Login')
const Login = () => import('@/views/login/Login')
const Register = () => import('@/views/pages/Register')
const ForgotPassword = () => import('../components/login/ForgotPassword')
const ResetPassword = () => import('../components/login/ResetPassword')

const Projects = () => import('@/views/Projects')
const CreateUpdateProject = () => import('@/components/projects/CreateUpdateProject.vue')
const Teams = () => import('@/views/Teams')

const CustomerSupport = () => import('@/views/supports/CustomerSupport')
const SupportView = () => import('@/views/supports/SupportView')
const Feedbacks = () => import('@/views/Feedbacks')

const routes = [
        {
            path: '/',
            redirect: '/login',
            name: 'Login',
            component: Login,
            meta: {
                auth: false
            },
        },
        {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                auth: false
            },
        },
        {
            path: '/reset-password',
            name: 'ResetPassword',
            component: ResetPassword,
            // meta: {
            //   auth: false
            // },
        },
        {

            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            meta: {
                auth: true
            },

            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path: 'history',
                    name: 'History',
                    component: NewHistory
                },
                {
                    path: 'history/create',
                    name: 'HistoryCreate',
                    component: HistoryCreate
                },
                {
                    path: ':model/:id/history',
                    name: 'SingleHistory',
                    component: HistoryTracking
                },
                {
                    path: 'projects',
                    meta: {
                        label: 'Projects'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Projects',
                            component: Projects
                        },
                        {
                            path: 'create',
                            name: 'CreateProject',
                            component: CreateUpdateProject
                        },
                        {
                            path: 'edit/:id',
                            name: 'EditProject',
                            component: CreateUpdateProject
                        }
                    ]
                },
                {
                    path: 'consumers',
                    meta: {
                        label: 'Consumers'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Consumers',
                            component: Consumer,
                        },
                        {
                            path: 'create',
                            name: 'CreateConsumer',
                            component: CreateConsumer,
                        },
                        {
                            path: ':id',
                            name: 'ViewConsumer',
                            component: ViewConsumer,
                        },
                        {
                            path: ':id/edit',
                            name: 'EditConsumer',
                            component: EditConsumer,
                        },
                        {
                            path: '/consumer-import',
                            name: 'ConsumerImport',
                            component: ConsumerImport,
                        },
                    ]
                },
                {
                    path: 'customer',
                    meta: {
                        label: 'Customers'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Customers',
                            component: Customer,
                        },

                        {
                            path: 'create',
                            name: 'CreateCustomer',
                            component: CreateCustomer,
                        },
                        {
                            path: ':id',
                            name: 'Details',
                            component: CustomerView,
                        },
                        {
                            path: ':id/assign-meter',
                            name: 'Assign Meter',
                            component: AssignMeterToCustomer,
                            // beforeEnter: checkAirlineSettingsInfoFromDashboard,
                        },
                        {
                            path: '/customer-import',
                            name: 'CustomerImport',
                            component: CustomerImport,
                        },
                    ]
                },
                {
                    path: 'customer-accounts',
                    meta: {
                        label: 'Customer Accounts'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'CustomerAccounts',
                            component: CustomerAccount,
                        },
                        {
                            path:':id',
                            name: 'Customer View',
                            component: CustomerAccView,
                        },
                        {
                            path: ':id/assign-meter',
                            name: 'AssignMeterToCustomerACC',
                            component: AssignMeterToCustomerAcc,
                        },
                    ]
                },
                {
                    path: 'customer-applications',
                    meta: {
                        label: 'Customer Applications'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'ApplicationList',
                            component: CustomerApplication,
                        },
                        {
                            path: 'create',
                            name: 'CreateApplication',
                            component: CreateApplication,
                        },
                        {
                            path: ':id',
                            name: 'CustomerApplicationView',
                            component: CustomerApplicationView,
                        },
                        {
                            path: ':id/edit',
                            name: 'CustomerApplicationEdit',
                            component: EditApplication,
                        }
                    ]
                },
                {
                    path: 'customer-feedbacks',
                    meta: {
                        label: 'Customer Feedbacks'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'CustomerFeedbacks',
                            component: Feedbacks,
                        }
                    ]
                },
                {
                    path: 'meters',
                    meta: {
                        label: 'Meters'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Meters',
                            component: Meters,
                        },
                        {
                            path: 'scan-and-store',
                            name: 'ScanAndStoreMeter',
                            component: ScanAndStoreMeter
                        },
                        {
                            path: ':id',
                            name: 'MeterView',
                            component: MeterView
                        },
                        {
                            path: ':id/consumptions',
                            name: 'Consumptions',
                            component: MetersConsumptionsView,
                            // beforeEnter: checkAirlineSettingsInfoFromDashboard,
                        },
                        {
                            path: ':id/errors',
                            name: 'Error Logs',
                            component: MeterErrors,
                            // beforeEnter: checkAirlineSettingsInfoFromDashboard,
                        },

                    ]
                },
                {
                    path: 'meter-consumptions',
                    meta: {
                        label: 'MeterConsumptions'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'MeterConsumptions',
                            component: MeterConsumption,
                        },
                    ]
                },
                {
                    path: 'tbl-reading-view',
                    meta: {
                        label: 'TblReadingView'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'TblReadingView',
                            component: TblReadingView,
                        },

                    ]
                },
                {

                    path: 'error-logs',
                    meta: {
                        label: 'MeterErrorLog'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'MeterErrorLog',
                            component: MeterErrorLog,
                        },

                    ]
                },
                {

                    path: 'notifications',
                    meta: {
                        label: 'Notification'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Notification',
                            component: Notification,
                        },

                    ]
                },

                {
                    path: 'tasks',
                    meta: {
                        label: 'Tasks'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Tasks',
                            component: Tasks,
                        },
                        {
                            path: ':id',
                            name: 'TaskView',
                            component: TaskView
                        },


                    ]
                },

                {
                    path: 'water-vehicle-requests',
                    meta: {
                        label: 'Water Vehicle Requests'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Water Vehicle Requests',
                            component: WaterVehicleRequests
                            ,
                        },

                        {
                            path: ':id',
                            name: 'Water Vehicle Detail',
                            component: WaterVehicleRequestsView
                        }
                    ]
                },
                {
                    path: 'reading',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'generate',
                            name: 'GenerateReading',
                            component: GenerateReading,
                        },
                        {
                            path: 'list',
                            name: 'ReadingList',
                            component: ReadingList,
                        },

                    ]
                },
                {
                    path: 'bill',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'generate',
                            name: 'GenerateBill',
                            component: GenerateBill,
                        },
                        {
                            path: 'list',
                            name: 'BillList',
                            component: BillList,
                        },

                    ]
                },
                {
                    path: 'bills',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'generate',
                            name: 'BillGenerate',
                            component: BillGenerate,
                        },
                        {
                            path: '',
                            name: 'Bills',
                            component: Bills,
                        },
                        {
                            path: 'create',
                            name: 'CreateBill',
                            component: BillCreate,
                        },
                        {
                            path: 'edit/:id',
                            name: 'EditBill',
                            component: BillCreate,
                        },
                        {
                            path: 'import',
                            name: 'ImportBill',
                            component: ImportBill,
                        },
                        {
                            path: 'prices',
                            name: 'PriceList',
                            component: PriceList,
                        },
                        {
                            path: 'prices/create',
                            name: 'CreatePrice',
                            component: CreatePrice,
                        },
                        // {
                        //   path: 'settlements',
                        //   name: 'SettlementList',
                        //   component: SettlementList,
                        // },
                        // {
                        //   path: 'settlements/create',
                        //   name: 'CreateSettlement',
                        //   component: CreateSettlement,
                        // },
                        // {
                        //   path: 'settlements/:id',
                        //   name: 'SettlementView',
                        //   component: SettlementView,
                        // },
                        // {
                        //   path: 'settlements/:id/edit',
                        //   name: 'EditSettlement',
                        //   component: EditSettlement,
                        // },

                    ]
                },
                {
                    path: 'customer-supports',
                    component:{
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Customer Supports',
                            component: CustomerSupport,

                        },
                        {
                            path: ':id',
                            name: 'CustomerSupportView',
                            component: SupportView,

                        },

                    ]
                },

                {
                    path: 'payments',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Payments',
                            component: Payments,

                        },

                    ]
                },

                // {
                //   path: 'theme',
                //   redirect: '/theme/colors',
                //   name: 'Theme',
                //   component: {
                //     render(c) { return c('router-view') }
                //   },
                //   children: [
                //     {
                //       path: 'colors',
                //       name: 'Colors',
                //       component: Colors
                //     },
                //     {
                //       path: 'typography',
                //       name: 'Typography',
                //       component: Typography
                //     }
                //   ]
                // },
                // {
                //   path: 'charts',
                //   name: 'Charts',
                //   component: Charts
                // },
                // {
                //   path: 'widgets',
                //   name: 'Widgets',
                //   component: Widgets
                // },
                {
                    path: 'teams',
                    meta: {
                        label: 'Teams'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Teams',
                            component: Teams
                        }
                    ]
                },
                {
                    path: 'inventory',
                    meta: {
                        label: 'Inventory'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: 'meters',
                            name: 'Meters',
                            component: InventoryMeters,
                        }, {
                            path: 'import-meters',
                            name: 'MetersImport',
                            component: InventoryMetersImport,
                        }

                    ]
                },
                {
                    path: 'reports',
                    meta: {
                        label: 'Reports'
                    },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Reports',
                            component: Reports,
                        },
                        {
                            path: 'billing',
                            name: 'Billing',
                            component: BillingReports,
                        },
                        {
                            path: 'arrears-billing',
                            name: 'Arrears',
                            component: ArrearsBillingReports,
                        },
                        {
                            path: 'ledger-billing',
                            name: 'Ledger',
                            component: LedgerBillingReports,
                        },
                        {
                            path: 'billing/monthly',
                            name: 'Monthly',
                            component: MonthlyReports,
                            // meta: {
                            //   roles : 'user-view'
                            // },
                            // beforeEnter: checkPermission,
                        },
                        {
                            path: 'billing/quarterly',
                            name: 'Quarterly',
                            component: QuarterlyBillingReport,
                        },

                        {
                            path: 'billing/half-yearly',
                            name: 'HalfYearly',
                            component: HalfYearlyBillingReport,
                        },
                        {
                            path: 'biling/yearly',
                            name: 'Yearly',
                            component: YearlyBillingReport,
                        },
                        {
                            path: 'billing/analytics',
                            name: 'Analytics',
                            component: BillingAnalytics,
                        },
                        {
                            path: 'growth',
                            name: 'GrowthReport',
                            component: GrowthReport,
                        },
                        {
                            path: 'ward',
                            name: 'WardReport',
                            component: WardReport,
                        }
                    ]
                },

            ]
        },
        {
            path: "/login",
            name: "Login",
            component: Login
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: '403',
                    name: 'Page403',
                    component: Page403
                },

                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                }
            ]
        },
        { path: "*", component: Page404 }
    ];

export default routes


