<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main justify-content-center">
          <CContainer  fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import cookieHelper from "../helpers/cookieHelper";
const TheSidebar = () => import(/* webpackChunkName: "TheSidebar" */ "./TheSidebar");
const TheHeader = () => import(/* webpackChunkName: "TheHeader" */ "./TheHeader");
const TheFooter = () => import(/* webpackChunkName: "TheFooter" */ "./TheFooter");

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  methods: {
    registerDevice() {
      this.$messaging
        .getToken({ vapidKey: `${process.env.VUE_APP_FIREBASE_VAPID_KEY}` })
        .then((currentToken) => {          
          if (currentToken) {
            this.axios
              .post(`/auth/save-token`, {
                token: currentToken,
                device_uuid: cookieHelper.getCookie("dv_uuid"),
              })
              .then(() => {
                cookieHelper.setCookie("_ntpers", "yes", 672); // 28 days
              });
          } else {
            cookieHelper.setCookie("_ntpers", "denied", 168); // 7 days
          }
        })
        .catch((err) => {
          console.log(err);
          cookieHelper.setCookie("_ntpers", "denied", 168); // 7 days
        });
    },
  },
  mounted() {
    if (
      this.$auth.ready() &&
      this.$auth.check() &&
      !cookieHelper.getCookie("_ntpers")
    ) {
      this.registerDevice();
    }

    this.$messaging.onMessage(function(payload) {
      const noteTitle = payload.notification.title;
      const noteOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      new Notification(noteTitle, noteOptions);
    });


// ==== Checking browsers Notification permission changes ===
    var Notification =
      window.Notification ||
      window.mozNotification ||
      window.webkitNotification;

    var was_questioned = false;
    if (Notification.permission == "default") {
      was_questioned = true;
    }

    Notification.requestPermission((permission) => {
      if (was_questioned) {
        if(permission === 'granted') {
          this.registerDevice();  
        }
      }
      if ("permissions" in navigator) {
        navigator.permissions
          .query({ name: "notifications" })
          .then((notificationPerm) => {
            notificationPerm.onchange = () => {             
              if(notificationPerm.state === 'granted') {
                this.registerDevice();  
              }
            };
          });
      }
    });
  }  
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  opacity: 2;
  background:linear-gradient(0deg, rgba(252, 249, 252, 0.3), rgb(245, 242, 244));
  background-size:cover;
  pointer-events: none;

}
</style>
