import Vue from 'vue'

Vue.mixin({
  methods: {
    checkUsrPermissions(reqPermissions) {
      if (!reqPermissions) return true;
      for (let key in reqPermissions) {
        let permStr = reqPermissions[key];
        var newPermArr = permStr.split('_');
        if(!newPermArr[1]) {
            permStr += 'all';
        }

        let usrPermissions = this.$auth.user().scopes;

        if (
          usrPermissions &&
          usrPermissions.includes(permStr)
        ) {
          return true;
        }
      }

      return false;
    },
    formatPrice(value) {
      if(!value) return value;
      return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    _dateTimeFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    _dateFormat(date) {
      return moment(date).format("Do MMM, YYYY");
    },
  }
})
