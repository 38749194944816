import axios from "axios";

const state = () => ({
  latestTransactions: [],
  payments: [],
  rows: 1,
  perPage: 10,
});

const getters = {
  latestTransactions: (state) => {
    return state.latestTransactions;
  },
  payments: (state) => {
    return state.payments;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
};

// actions
const actions = {
  getLatestTransactions({ commit, dispatch }) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`/admin/payments?limit=3&status=Successful`)
        .then((res) => {
          commit("setlatestTransactions", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getPayments({ commit, dispatch }, search) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(
          `/admin/payments?status=${search && search.status ? search.status : ""}&page=${
            search && search.currentPage ? search.currentPage : ""
          }`
        )
        .then((res) => {
          commit("setPayments", res.data.data);
          commit(
            "setRows",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("setPerPage", parseInt(res.data.meta.per_page));
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },
};

// mutations
const mutations = {
  setlatestTransactions(state, latestTransactions) {
    state.latestTransactions = latestTransactions;
  },
  setPayments(state, payments) {
    state.payments = payments;
  },
  setRows(state, rows) {
    state.rows = rows;
  },
  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
