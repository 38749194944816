import axios from "axios";

const state = {
  notices: [],
  notice: { channel: [], audience: { area:[], ward_number:[]}},
  rows: 1,
  perPage: 10,
  meta: {}
};

const getters = {
  notices: (state) => {
    return state.notices;
  },
  notice: (state) => {
    return state.notice;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  meta: (state) => {
    return state.meta;
  }
};

const actions = {
  getNotices({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `/admin/notices?page=${filter && filter.currentPage ? filter.currentPage : ""}&status=${filter && filter.status ? filter.status : ""}`
        )
        .then((res) => {
          commit("SET_NOTICES", res.data.data);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  find({ commit }, id) {
    return axios.get(`/admin/notices/${id}`).then((res) => {
      commit("SET_NOTICE", res.data.data);
    });
  },

  store({ commit }, payload) {
    return axios.post(`/admin/notices`, payload).then((res) => {
      commit("ADD_NOTICE", res.data.data);
    });
  },

  update({ commit }, payload) {
    return axios.put(`/admin/notices/${payload.id}`, payload).then((res) => {
      commit("UPDATE_NOTICE", res.data.data);
    });
  },

  publish({ commit }, id) {
    return axios.get(`/admin/notices/${id}/publish`).then((res) => {
      commit("SET_NOTICE", res.data.data);
    });
  },

  destroy({ commit }, id) {
    return axios.delete(`/admin/notices/${id}`).then((res) => {
      commit("DELETE_NOTICE", id);
      return res;
    });
  },

};

const mutations = {
  SET_NOTICES(state, notices) {
    state.notices = notices;
  },
  SET_NOTICE(state, notice) {
    state.notice = notice;
  },
  ADD_NOTICE(state, notice) {
    state.notices.unshift(notice);
  },
  UPDATE_NOTICE(state, notice) {
    state.notices.map((item, index) => {
      if (item.id == notice.id) {
        state.notices[index] = notice;
      }
    });
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  DELETE_NOTICE(state, id) {
    const i = state.notices.map((item) => item.id).indexOf(id);
    state.notices.splice(i, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
