import axios from 'axios';

const state = {
    profile: {},
    user: {}
};

const getters = {
    profile: state => {
        return state.profile;
    },
    user: state => {
        return state.user;
    }
};

const actions = {
    changePassword({ commit }, data) {
        return axios
            .put(`admin/users/update-password`, data)
            .then((res) => {
                commit('SET_PROFILE', res.data.data);
            });
    },
    getUserData({ commit,dispatch }) {
        dispatch('Settings/loading', true, { root: true });
        axios
            .get("auth/me?from=admin")
            .then((res) => {
                commit('USER', res.data.data);
                dispatch('Settings/loading', false, { root: true });
            })
            .catch((err) => {
                console.log(err);
                dispatch('Settings/loading', false, { root: true });
            });
    },
};

const mutations = {
    SET_PROFILE(state, profile) {
        state.profile = profile;
    },
    USER(state, user) {
        state.user = user;

    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}