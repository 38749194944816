import axios from "axios";

const state = () => ({
  latestWaterVehicleRequests: [],
  waterVehicleRequests: [],
  waterVehicleRequest: { payments: [] },
  rows: 1,
  perPage: 10,
  meta: {},
});

const getters = {
  latestWaterVehicleRequests: (state) => {
    return state.latestWaterVehicleRequests;
  },
  waterVehicleRequests: (state) => {
    return state.waterVehicleRequests;
  },
  waterVehicleRequest: (state) => {
    return state.waterVehicleRequest;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  meta: (state) => {
    return state.meta;
  },
};

// actions
const actions = {
  getLatestWaterVehicleRequests({ commit, dispatch }) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`admin/water-vehicle-requests?limit=3`)
        .then((res) => {
          commit("setlatestWaterVehicleRequests", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getWaterVehicleRequests({ commit, dispatch }, search) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(
          `admin/water-vehicle-requests?status=${
            search && search.status ? search.status : ""
          }&page=${search && search.currentPage ? search.currentPage : ""}`
        )
        .then((res) => {
          commit("setWaterVehicleRequest", res.data.data);
          commit(
            "setRows",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("setPerPage", parseInt(res.data.meta.per_page));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  markAsDelivered({ commit, dispatch }, request) {
    dispatch("Settings/loading", true, { root: true });

    if (request && request.status === "COMPLETED") {
      dispatch("Settings/loading", false, { root: true });
      return false;
    }

    try {
      axios
        .get(
          `/admin/water-vehicle-requests/${
            request && request.id ? request.id : ""
          }/mark-as-delivered`
        )
        .then(() => {
          request.status = "COMPLETED";
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getWaterVehicleRequestById({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`/admin/water-vehicle-requests/${id}`)
        .then((res) => {
          commit("getWaterVehicleRequestById", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },
};

// mutations
const mutations = {
  setlatestWaterVehicleRequests(state, latestWaterVehicleRequests) {
    state.latestWaterVehicleRequests = latestWaterVehicleRequests;
  },
  setWaterVehicleRequest(state, waterVehicleRequests) {
    state.waterVehicleRequests = waterVehicleRequests;
  },
  setRows(state, rows) {
    state.rows = rows;
  },
  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  getWaterVehicleRequestById(state, waterVehicleRequest) {
    state.waterVehicleRequest = waterVehicleRequest;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
