import axios from 'axios';
import router from '../router/index'

const state = () => ({
    total_meters: 0,
    total_consumptions: 0,
    total_customers: 0,
    total_vehicle_requests: 0,
    total_revenue: 0,
    total_connected_meter: 0,
    total_disconnected_meter: 0,
    dateWiseConsumptions: [],
    autoSearchData: [],
    searchData: {
        customer: 'CustomerPage',
        bill: 'BillPage',
        meter: 'MeterPage'
    },
    labelsArray: [],
    chartDataArray: [],
    topConsumptions: [],

})

const getters = {
    total_meters: state => {
        return state.total_meters;
    },
    total_consumptions: state => {
        return state.total_consumptions;
    },
    total_customers: state => {
        return state.total_customers;
    },
    total_vehicle_requests: state => {
        return state.total_vehicle_requests;
    },
    dateWiseConsumptions: state => {
        return state.dateWiseConsumptions;
    },
    total_revenue: state => {
        return state.total_revenue;
    },
    total_connected_meter: state => {
        return state.total_connected_meter;
    },
    total_disconnected_meter: state => {
        return state.total_disconnected_meter;
    },

    newChartData: (state, commit) => {
        return state.chartDataArray
    },
    chartLabels: (state, commit) => {
        return state.labelsArray
    },
    autoSearchData: state => {
        return state.autoSearchData;
    },
    topConsumptions: state => {
        return state.topConsumptions;
    }
}

// actions
const actions = {

    dashboardCounts({ commit, dispatch }) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(`admin/dashboard/counts`)
                .then((res) => {
                    let counts = res.data;
                    commit('setTotalMeters', counts.totalMeters)
                    // commit('setTotalConsumptions', counts.totalConsumption.toFixed(2))
                    commit('setTotalCustomers', counts.totalCustomers)
                    // commit('setTotalVehicleRequests', counts.totalVehicleRequests)
                    commit('setTotalRevenue', counts.totalRevenue)
                    commit('setTotalConnectedMeter', counts.totalConnectedMeter)
                    commit('setTotalDisconnectedMeter', counts.totalDisconnectedMeter)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }
    },

    getDateWiseMeterConsumptionsData({ commit, dispatch }, stateData) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(`admin/meters/date-wise/consumption?search=${stateData ? stateData : 'week'}`)
                .then((res) => {
                    commit('setDateWiseConsumptions', res.data.consumptions)
                    dispatch('setBarChartData');
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });

                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });

        }
    },

    getMeterConsumptionsDataByMeter({ commit, dispatch }, {meterId, stateData}) {
        return axios
            .get(`admin/meters/${meterId}/chart-data?search=${stateData ? stateData : 'week'}`)
            .then((res) => {
                commit('setDateWiseConsumptions', res.data.consumptions)
                dispatch('setBarChartData');
            })
            .catch((err) => {
                console.log(err);
            });
    },

    setBarChartData({ commit, state }) {
        var labelArray = [];
        var chartDataArray = [];
        state.dateWiseConsumptions.forEach((element) => {
            labelArray.push(element.date);
            chartDataArray.push(element.total_unit);
        });

        chartDataArray = _.map(chartDataArray, (item, i) => {
            return chartDataArray[i + 1] ? (chartDataArray[i + 1] - item).toFixed(2) : 0;
        });
        labelArray.shift();
        chartDataArray.pop();

        var cArr = [
            {
                label: "Consumption",
                backgroundColor: "#3D5B96",
                data: chartDataArray,
                categoryPercentage: 0.4,
                barPercentage: 1
            },
        ];
        commit('SET_LABELS', labelArray);
        commit('SET_BAR_CHART_DATA', cArr);
    },

    resetBarChartData({commit}) {
        commit('RESET_BAR_CHART_DATA');
    },

    dashboardSearch({ state, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(`admin/dashboard/search?search=${search ? search : ''}`)
                .then((res) => {
                    if (res.data.pageName == state.searchData.customer && res.data.accountNumber == search) {
                        router.replace({ name: 'Consumers', query: { column: 'account_number', keyword: search, currentPage:1 } }, () => { });
                    } else if (res.data.pageName == state.searchData.customer && res.data.mobileNumber == search) {
                        router.replace({ name: 'Consumers', query: { column: 'mobile_number', keyword: search, currentPage:1 } }, () => { });
                    }
                    else if (res.data.pageName == state.searchData.meter && res.data.hardwareSerialNo == search) {
                        router.push({ name: 'Meters', query: { column: 'meter_number', keyword: search, currentPage:1 } }, () => { });
                    } else if (res.data.pageName == state.searchData.bill) {
                        router.push({ name: 'Bills' }, () => { });
                    }

                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });

                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });

        }

    },

    autoSuggestion({ dispatch, state }, search) {
        dispatch('Settings/loading', true, { root: true });

        if (search) {
            axios
                .get(`/admin/dashboard/auto-suggestion`, {
                    params: { search: search },
                })
                .then((res) => {
                    dispatch('Settings/loading', false, { root: true });

                    state.autoSearchData = [];
                    state.autoSearchData[0] = res.data[0].customer
                        ? res.data[0].customer
                        : "";
                    state.autoSearchData[1] = res.data[1].meter ? res.data[1].meter : "";
                    state.autoSearchData[2] = res.data[2].bill ? res.data[2].bill : "";
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', true, { root: true });

                });
        }
    },

    getTopConsumptions({commit, dispatch}, stateData) {
        dispatch('Settings/loading', true, {root: true});
        try {
            axios.get(`admin/top-consumptions`).then((res) => {
                commit('SET_TOP_CONSUMPTIONS', res.data.top_consumptions)
                dispatch('Settings/loading', false, {root: true});
            }).catch((err) => {
                console.log(err);
                dispatch('Settings/loading', false, {root: true});
            });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, {root: true});
        }
    },

}

// mutations
const mutations = {
    setTotalMeters(state, total_meters) {
        state.total_meters = total_meters;
    },
    setTotalConsumptions(state, total_consumptions) {
        state.total_consumptions = total_consumptions;
    },
    setTotalCustomers(state, total_customers) {
        state.total_customers = total_customers;
    },
    setTotalVehicleRequests(state, total_vehicle_requests) {
        state.total_vehicle_requests = total_vehicle_requests;
    },
    setDateWiseConsumptions(state, dateWiseConsumptions) {
        state.dateWiseConsumptions = dateWiseConsumptions;
    },
    setTotalRevenue(state, total_revenue) {
        state.total_revenue = total_revenue;
    },

    setTotalConnectedMeter(state, total_connected_meter) {
        state.total_connected_meter = total_connected_meter;
    },
    setTotalDisconnectedMeter(state, total_disconnected_meter) {
        state.total_disconnected_meter = total_disconnected_meter;
    },
    SET_LABELS(state, labels) {
        state.labelsArray = labels;
    },
    SET_BAR_CHART_DATA(state, data) {
        state.chartDataArray = data;
    },
    autoSearchData(state, data) {
        state.autoSearchData = data;
    },
    RESET_BAR_CHART_DATA(state) {
        state.labelsArray = [];
        state.chartDataArray = [];
    },
    SET_TOP_CONSUMPTIONS(state, data) {
        state.topConsumptions = data;
    }
    // setLoading(state, isLoading) {
    //     state.isLoading = isLoading;
    // }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

