import axios from "axios";

const state = () => ({
  prices: [],
});

const getters = {
  prices: (state) => {
    return state.prices;
  },
};

// actions
const actions = {
  getPrices({ commit, dispatch }) {
    dispatch("Settings/loading", true, { root: true });
    try {
      return axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/admin/prices`
        )
        .then((res) => {
          commit("SET_PRICES", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch(() => {
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      dispatch("Settings/loading", false, { root: true });
    }
  },

  store({ commit }, data) {
    return axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/admin/prices`,
        data)
      .then((res) => {
        commit("ADD_PRICE", res.data.data);
        return res;
      });
  },
};

// mutations
const mutations = {
  SET_PRICES(state, prices) {
    state.prices = prices;
  },
  ADD_PRICE(state, data) {
    state.prices.unshift(data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
