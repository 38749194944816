<script>
export default {
  name: "TableColumn",
  props: {
    name: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    align: {
      type: String,
      required: false,
      default: "left",
    }
  },
  computed: {
    active() {
      return this.id === this.sort;
    }
  },
  methods: {
    sortColumn() {
      if (this.active) {
        if (this.position === "asc") {
          this.$emit("sort", [this.sort, "desc"]);
        } else {
          this.$emit("sort", [this.sort, "asc"]);
        }
      } else {
        this.$emit("sort", [this.sort, "asc"]);
      }
    }
  }
}
</script>

<template>
  <th scope="col">
    <div @click.prevent="sortColumn" style="display: flex; align-items: center; cursor: pointer; width: 100%;" :class="align" >
      <span>{{ name }}</span>
      <div v-if="active" style="display: flex; flex-direction: column; margin-left: 5px;">
        <svg v-if="position === 'desc'" style="width: 10px; height: 10px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor"
             data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5"/>
        </svg>

        <svg v-if="position === 'asc'" style="width: 10px; height: 10px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor"
             data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
        </svg>
      </div>

      <div v-else style="display: flex; flex-direction: column; margin-left: 5px;">
        <svg style="width: 10px; height: 10px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor"
             data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5"/>
        </svg>

        <svg style="width: 10px; height: 10px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor"
             data-slot="icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
        </svg>
      </div>
    </div>
  </th>
</template>

<style scoped lang="scss">
.left {
  justify-content: left;
}

.center {
  justify-content: center;
}

.right {
  justify-content: right;
}
</style>