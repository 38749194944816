import axios from 'axios';

const state = {
    teams: [],
    rows: 1,
    perPage: 10,
    meta: {},
};

const getters = {
    teams: state =>{
        return state.teams;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
    meta: state => {
        return state.meta;
    },
};

const actions = {
    getTeams({commit, dispatch}, filter){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/teams?page=${filter && filter.currentPage ? filter.currentPage : ''}&sortBy=${filter && filter.sortBy ? filter.sortBy : ''}&sortOrder=${filter && filter.sortOrder ? filter.sortOrder : ''}`)
                .then((res) => {

                    commit('SET_TEAMS', res.data.data);
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('SET_META', res.data.meta)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    getTeamList({commit, dispatch}, perPage){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/teams?per_page=${perPage  ? perPage : '10'}`)
                .then((res) => {

                    commit('SET_TEAMS', res.data.data);
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    storeTeam({commit}, payload){
        return axios
            .post(`/admin/teams`, payload)
            .then((res) => {
                commit('ADD_TEAM', res.data.data);
            });
    },

    updateTeam({commit}, team){
        return axios
            .put(`/admin/teams/${team.id}`, team)
            .then((res) => {
                commit('UPDATE_TEAM', res.data.data);
            });
    }
};

const mutations = {
    SET_TEAMS (state, teams){
        state.teams = teams;
    },
    ADD_TEAM (state, user){
        state.teams.unshift(user);
    },
    UPDATE_TEAM (state, user){
        state.teams.map((item,index)=>{
            if(item.id == user.id){
                state.teams[index] = user;
            }
        });
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
