import axios from 'axios';

const state = {
    holidays: [],
    holidayLists: [],
    rows: 1,
    meta: {},
    perPage: 10,
};

const getters = {
    holidays: state =>{
        return state.holidays;
    },
    holidayLists: state =>{
        return state.holidayLists;
    },
    rows: state => {
        return state.rows;
    },
    meta: state => {
        return state.meta;
    },
    perPage: state => {
        return state.perPage;
    },
};

const actions = {
    getHolidays({commit, dispatch}, currentPage){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/holidays?page=${currentPage  ? currentPage : '1'}`)
                .then((res) => {

                    commit('setHolidays', res.data.data);
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setMeta', res.data.meta)
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    getHolidayLists({commit, dispatch}, currentPage){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/holidays/lists?page=${currentPage  ? currentPage : '1'}`)
                .then((res) => {

                    commit('setHolidayLists', res.data.data);
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    storeHoliday({commit}, payload){
        return axios
            .post(`/admin/holidays`, payload)
            .then((res) => {
                commit('updateHolidaysList', res.data.data);
            });
    },

    updateHoliday({commit}, user){
        return axios
            .put(`/admin/holidays/${user.id}`, user)
            .then((res) => {
                commit('updateEditedHoliday', res.data.data);
            });
    },
    destroy({ commit }, id) {
        return axios.delete(`/admin/holidays/${id}`).then((res) => {
            commit("DELETE_NOTICE", id);
            return res;
        });
    },
};

const mutations = {
    setHolidays(state, holidays){
        state.holidays = holidays;
    },
    setHolidayLists(state, holidays){
        state.holidayLists = holidays;
    },
    updateHolidaysList(state, user){
        state.holidays.unshift(user);
    },
    updateEditedHoliday(state, user){
        state.holidays.map((item,index)=>{
            if(item.id == user.id){
                state.holidays[index] = user;
            }
        });
    },
    DELETE_NOTICE(state, id) {
        const i = state.holidays.map((item) => item.id).indexOf(id);
        state.holidays.splice(i, 1);
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setMeta(state, meta) {
        state.meta = meta;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
