<template>
  <div id="app" v-cloak>
    <vue-confirm-dialog></vue-confirm-dialog>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#ff5c12"
      :height="90"
      :width="90"
      :lock-scroll="true"
      :is-full-page="fullPage"
    ></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Loading,
  },
  data() {
    return {
      fullPage: true,
    };
  },  
  computed: {
    ...mapGetters("Settings",["isLoading"]),
  },
  mounted() {
    this.$nextTick(function () {
      this.$store.dispatch("Settings/loading", false);
    })    
  },  
  created() {
    this.$store.dispatch("Settings/loading", true);
  }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
