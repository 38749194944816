<script>
export default {
  name: "DataTable",
}
</script>

<template>
  <div class="table-responsive" id="printTargetDiv">
    <table class="table">
      <thead class="table-light">
      <tr>
        <slot name="header" />
      </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.table {
  font-size: 14px;

  thead {
    tr {
      th {
        background: #f8f9fa;
        color: #000;
      }
    }
  }

  tbody {
    font-size: 12px;

    tr {
      color: #000;

      td {
        padding: 0.75rem 0 0.75rem 0.75rem;

        button {
          font-size: 13px;
        }

        span {
          cursor: pointer;
        }

        span .color-view {
          color: #5e76e7;
          padding: 10px;
        }
      }

      td:nth-child(2) {
        font-weight: bold;
        color: #5e76e7;
      }
    }
  }
}
</style>