import axios from 'axios';

const state = {
    micodes: [],
    micodeLists: [],
    rows: 1,
    perPage: 10,
    meta: {},
};

const getters = {
    micodes: state =>{
        return state.micodes;
    },
    micodeLists: state =>{
        return state.micodeLists;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
    meta: state => {
        return state.meta;
    },
};

const actions = {
    getMiCodes({commit, dispatch}, filter){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/micodes?page=${filter.currentPage}&sortBy=${filter.sortBy}&sortOrder=${filter.sortOrder}`)
                .then((res) => {

                    commit('setMiCodes', res.data.data);
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('SET_META', res.data.meta)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    getMiCodeLists({commit, dispatch}, currentPage){
        dispatch('Settings/loading', true,{root:true});
        try {
            axios
                .get(`/admin/micodes/lists?page=${currentPage  ? currentPage : '1'}`)
                .then((res) => {

                    commit('setMiCodeLists', res.data.data);
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false,{root:true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false,{root:true});
        }

    },
    storeMiCode({commit}, payload){
        return axios
            .post(`/admin/micodes`, payload)
            .then((res) => {
                commit('updateMiCodesList', res.data.data);
            });
    },

    updateMiCode({commit}, user){
        return axios
            .put(`/admin/micodes/${user.id}`, user)
            .then((res) => {
                commit('updateEditedMiCode', res.data.data);
            });
    },
    destroy({ commit }, id) {
        return axios.delete(`/admin/micodes/${id}`).then((res) => {
            commit("DELETE_NOTICE", id);
            return res;
        });
    },
};

const mutations = {
    setMiCodes(state, micodes){
        state.micodes = micodes;
    },
    setMiCodeLists(state, micodes){
        state.micodeLists = micodes;
    },
    updateMiCodesList(state, user){
        state.micodes.unshift(user);
    },
    updateEditedMiCode(state, user){
        state.micodes.map((item,index)=>{
            if(item.id == user.id){
                state.micodes[index] = user;
            }
        });
    },
    DELETE_NOTICE(state, id) {
        const i = state.micodes.map((item) => item.id).indexOf(id);
        state.micodes.splice(i, 1);
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
