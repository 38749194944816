import axios from 'axios';

const state = {
    project: {}, projects: [], projectsList: [], rows: 1, perPage: 10, meta: {},
};

const getters = {
    project        : state => {
        return state.project;
    }, projects    : state => {
        return state.projects;
    }, projectsList: state => {
        return state.projectsList;
    }, rows        : state => {
        return state.rows;
    }, perPage     : state => {
        return state.perPage;
    },meta     : state => {
        return state.meta;
    },
};

const actions = {
    getProjects({commit, dispatch}, filter='') {
        dispatch('Settings/loading', true, {root: true});
        try {
            axios
                .get(`/admin/projects?page=${filter.currentPage ? filter.currentPage : '1'}&keyword=${filter && filter.keyword ? filter.keyword : ''}&sortBy=${filter && filter.sortBy ? filter.sortBy : ''}&sortOrder=${filter && filter.sortOrder ? filter.sortOrder : ''}`)
                .then((res) => {

                    commit('SET_PROJECTS', res.data.data);
                    commit('setRows', Math.ceil(res.data.meta.total / res.data.meta.per_page))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('setMeta', res.data.meta)
                    dispatch('Settings/loading', false, {root: true});
                })
                .catch((err) => {
                    dispatch('Settings/loading', false, {root: true});
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, {root: true});
        }

    },

    // This action is for projects select dropdown
    getProjectsList({commit, dispatch}) {
        dispatch('Settings/loading', true, {root: true});
        try {
            return axios
                .get(`/admin/projects/list`)
                .then((res) => {
                    commit('SET_PROJECTS_LIST', res.data.data);
                    dispatch('Settings/loading', false, {root: true});
                })
                .catch((err) => {
                    dispatch('Settings/loading', false, {root: true});
                });
        } catch (err) {
            dispatch('Settings/loading', false, {root: true});
        }

    },

    getProject({commit}, projectId) {
        return axios
            .get(`/admin/projects/${projectId}`)
            .then((res) => {
                commit('SET_PROJECT', res.data.data);
            });
    },

    storeProject({commit}, payload) {
        return axios
            .post(`/admin/projects`, payload)
            .then((res) => {
                commit('ADD_PROJECT', res.data.data);
            });
    },

    updateProject({commit}, project) {
        return axios
            .put(`/admin/projects/${project.id}`, project)
            .then((res) => {
                commit('UPDATE_PROJECT', res.data.data);
            });
    }
};

const mutations = {
    SET_PROJECTS(state, projects) {
        state.projects = projects;
    }, SET_PROJECT(state, project) {
        state.project = project;
    }, SET_PROJECTS_LIST(state, project) {
        state.projectsList = project;
    }, ADD_PROJECT(state, user) {
        state.projects.unshift(user);
    }, UPDATE_PROJECT(state, user) {
        state.projects.map((item, index) => {
            if (item.id == user.id) {
                state.projects[index] = user;
            }
        });
    }, setRows(state, rows) {
        state.rows = rows;
    }, setPerPage(state, perPage) {
        state.perPage = perPage;
    },setMeta(state, meta) {
        state.meta = meta;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations
}
