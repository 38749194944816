import axios from 'axios';

const state = () => ({
    customeSupports: [],
    support: {},
    rows: 1,
    perPage: 10,
    meta: {},
    widgetCount: {
        totalResolved: 0,
        totalPending: 0,
        totalOnHold: 0,
        totalUnAssigned: 0,
    }
})

const getters = {
    customeSupports: state => {
        return state.customeSupports;
    },
    support: state => {
        return state.support;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
    meta: state => {
        return state.meta;
    },
    widgetCount: state => {
        return state.widgetCount;
    }
}

// actions
const actions = {
    getCustomerSupports({ commit, dispatch }, filter) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(
                    `/admin/customer-supports?page=${filter && filter.currentPage ? filter.currentPage : ''}
                    &hardware_serial_no=${filter && filter.hardware_serial_no ? filter.hardware_serial_no : ''}
                    &account_number=${filter && filter.account_number ? filter.account_number : ''}
                    &mobile_number=${filter && filter.mobile_number ? filter.mobile_number : ''}`
                )
                .then((res) => {
                    commit('setCustomerSupport', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('SET_META', res.data.meta)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }
    },

    getCounts({ commit }) {
        return axios.get(`/admin/customer-supports/counts`)
                    .then((res) => {
                        commit('SET_WIDGET_COUNT', res.data)
                    });
    },

    store({ commit }, formData) {
        return axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/admin/customer-supports`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                commit("ADD_CUSTOMER_SUPPORT", res.data.data);
                // return res;
            });
    },

    statusUpdate({ commit }, support) {
        return axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/admin/customer-supports/${support.id}/update-status`,
                { status: support.status },
            )
            .then((res) => {
                commit("UPDATE_SUPPORT_STATUS", support);
            });
    },

    priorityUpdate({ commit }, support) {
        return axios
            .post(
                `${process.env.VUE_APP_API_BASE_URL}/admin/customer-supports/${support.id}/update-priority`,
                { priority: support.priority },
            )
            .then((res) => {
                commit("UPDATE_SUPPORT_PRIORITY", support);
            });
    },
    assignTeamToCustomer({commit}, { team, supportId }) {
        return axios
            .post(
            `${process.env.VUE_APP_API_BASE_URL}/admin/customer-supports/${supportId}/assign-team`,
            team
            )
            .then((res) => {
                commit("UPDATE_SUPPORT", res.data.data);
            });
    },
    getSupportDetail({ commit, dispatch }, supportId) {
        dispatch('Settings/loading', true, { root: true });
        try {
            return axios
                .get(`/admin/customer-supports/${supportId}`)
                .then((res) => {
                    commit('SET_SUPPORT', res.data.data);
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            dispatch('Settings/loading', false, { root: true });
        }

    },
}

// mutations
const mutations = {
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    setCustomerSupport(state, customerSupports) {
        state.customeSupports = customerSupports;
    },
    ADD_CUSTOMER_SUPPORT(state, data) {
        state.customeSupports.unshift(data);
    },
    UPDATE_SUPPORT(state, support) {
        state.customeSupports.map((item, index) => {
            if (item.id == support.id) {
                state.customeSupports[index] = support;
            }
        });
    },
    UPDATE_SUPPORT_STATUS(state, support) {
        state.customeSupports.map((item, index) => {
            if (item.id == support.id) {
                state.customeSupports[index]['status'] = support.status;
            }
        });
    },

    UPDATE_SUPPORT_PRIORITY(state, support) {
        state.customeSupports.map((item, index) => {
            if (item.id == support.id) {
                state.customeSupports[index]['priority'] = support.priority;
            }
        });
    },
    SET_WIDGET_COUNT(state, data) {
        state.widgetCount = {
            totalResolved: data.totalResolved,
            totalPending: data.totalPending,
            totalOnHold: data.totalOnHold,
            totalUnAssigned: data.totalUnAssigned,
        }
    },
    SET_SUPPORT(state, data) {
        state.support =  data;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

