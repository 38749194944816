import Vue from 'vue'
import Vuex from 'vuex'
import Dashboard from './modules/dashboard'
import SideBar from './modules/dynamicSideBar'
import WaterVehicleRequest from './modules/waterVehicleRequest'
import Meter from './modules/meter'
import Payment from './modules/payment'
import Reading from './modules/reading'
import Bill from './modules/bill'
import Settings from './modules/settings'
import Users from './modules/users'
import Constant from './modules/constant'
import Wards from './modules/wards'
import Areas from './modules/areas'
import ROCode from './modules/rocodes'
import RSCode from './modules/rscodes'
import MiCode from './modules/micodes'
import Holiday from './modules/holiday'
import Projects from './modules/projects'
import Teams from './modules/teams'
import Roles from './modules/roles'
import Permission from './modules/permission'
import Event from './modules/event'
import Feedback from './modules/feedback'
import Consumer from './modules/consumer'
import Customer from './modules/customer'
import CustomerAccount from './modules/customerAccount'
import CustomerApplication from './modules/customerApplication'
import BillSettlement from './modules/settlements'
import Report from './modules/Report'
import CustomerSupport from './modules/customerSupport'
import Task from './modules/tasks'
import Profile from './modules/profile'
import Notification from './modules/notification'
import Config from './modules/configs'
import Pricing from './modules/pricings'
import Rates from './modules/rates'
import Notice from './modules/notice'

Vue.use(Vuex)



export default new Vuex.Store({
  modules: {
    Dashboard,
    SideBar,
    WaterVehicleRequest,
    Meter,
    Payment,
    Reading,
    Bill,
    Settings,
    Users,
    Constant,
    Wards,
    Areas,
    ROCode,
    RSCode,
    MiCode,
    Holiday,
    Projects,
    Teams,
    Roles,
    Permission,
    Event,
    Feedback,
    Consumer,
    Customer,
    CustomerAccount,
    CustomerApplication,
    BillSettlement,
    Report,
    CustomerSupport,
    Task,
    Profile,
    Notification,
    Config,
    Pricing,
    Rates,
    Notice
  }
})
