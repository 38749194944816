<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true
    },
    sort: {
      type: Boolean,
      default: true
    },
    ordering: {
      type: String,
      default: null
    },
    active: {
      type: String,
      default: null
    },
    w: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      sortDirection: null,
      sortField: null
    }
  },
  computed: {
    isActive() {
      return this.sortField === this.active;
    }
  },
  methods: {
    sortBy(title) {
      title = title.toLowerCase().replace(/ /g, "_");
      this.$emit("sortBy", title);
    }
  },
  watch: {
    ordering: {
      handler: function (newVal) {
        if (!newVal) {
          this.sortDirection = null;
          this.sortField = null;
          return;
        }
        this.sortDirection = newVal[0] === '-' ? 'desc' : 'asc';
        this.sortField = newVal[0] === '-' ? newVal.slice(1) : newVal;
      },
      immediate: true
    }
  }
}
</script>

<template>
  <th scope="col">
    <div
        v-if="sort"
        class="d-flex align-items-center pointer"
        :class=" w ? '' : 'w-100'"
        :style="{width: w}"
        @click.prevent="sortBy(title)"
    >
      {{ title }}
      <div v-if="isActive">
        <i
            v-if="sortDirection === 'asc'"
            class="fa fa-sort-up ml-1"
        ></i>
        <i
            v-else-if="sortDirection === 'desc'"
            class="fa fa-sort-down ml-1"
        ></i>
      </div>
      <div v-else>
        <i class="fa fa-sort ml-1"></i>
      </div>
    </div>

    <div
        v-else
        :class=" w ? '' : 'w-100'"
        :style="{width: w}"
    >
      {{ title }}
    </div>
  </th>
</template>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>