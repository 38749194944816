import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import axios from "axios";
import VueAxios from "vue-axios";
import store from './store'
import VueAuth from '@websanova/vue-auth'
import VueToastr from "vue-toastr";
import moment from 'moment'
import auth from './guard/auth'
import VueConfirmDialog from 'vue-confirm-dialog'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })
Vue.use(ElementUI);

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import firebaseMessaging from './firebase'
import './registerServiceWorker'
import './mixins/generalMixin'
// Filters
import './filters.js'

Vue.prototype.$messaging = firebaseMessaging


// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);


Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
      window.event = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
              vnode.context[binding.expression](event);
          }
      };
      document.body.addEventListener('click', window.event)
  },
  unbind: function (el) {
      document.body.removeEventListener('click', window.event)
  },
});

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


window.moment = require('moment');

window._ = require('lodash');
Vue.config.performance = true
Vue.use(CoreuiVue)
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + '/';
Vue.use(VueAxios, axios)
Vue.router = router;
Vue.use(VueAuth, auth);
Vue.use(VueToastr, {
  defaultPosition: 'toast-bottom-right'
});
Vue.prototype.$log = console.log.bind(console)

axios.interceptors.response.use(response => {
  return response;
 }, error => {
 if (error.response.status === 403) {
    router.push('/pages/403')
 }
 return Promise.reject(error);
});
Vue.prototype.$datePickerFormat = process.env.VUE_APP_DATE_PICKER_FORMAT || 'dd-MM-yyyy';
Vue.prototype.$monthPickerFormat = process.env.VUE_APP_MONTH_PICKER_FORMAT || 'MMM-yyyy';
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
