import TheContainer from "@/containers/TheContainer.vue";

const routes = [
    {
        path: '',
        component: TheContainer,
        meta: {
            auth: true
        },
        children: [
            {
                path: '/rocodes',
                meta: {
                    label: 'ROCodes'
                },
                name: 'ROCodes',
                component: () => import('@/views/ROCodes.vue')
            },
            {
                path: '/rscodes',
                meta: {
                    label: 'RSCodes'
                },
                name: 'RSCodes',
                component: () => import('@/views/RsCode.vue')
            },
            {
                path: '/bills/rates',
                name: 'RateList',
                component: () => import('@/components/Rate/Rate.vue')
            },
            {
                path: '/bills/rates/create',
                name: 'CreateRate',
                component: () => import('@/components/Rate/CreateRate.vue')
            },
            {
                path: '/bills/rates/:id/edit',
                name: 'EditRate',
                component: () => import('@/components/Rate/CreateRate.vue')
            },
            {
                path: '/areas',
                meta: {
                    label: 'Areas'
                },
                name: 'Areas',
                component: () => import('@/views/Areas.vue')
            },
            {
                path: '/micodes',
                meta: {
                    label: 'MiCodes'
                },
                component: () => import('@/views/MiCode.vue')
            },
            {
                path: '/wards',
                name: 'Wards',
                component: () => import('@/views/information/ward/Index.vue')
            },
            {
                path: '/holidays',
                meta: {
                    label: 'Holidays'
                },
                name: 'Holidays',
                component: () => import('@/views/Holidays.vue')
            },
        ]
    }
];

export default routes;