import axios from 'axios';

const state = {
    tasks: [],
    task: {},
    rows: 1,
    perPage: 10,
    meta: {},
};

const getters = {
    tasks: state => {
        return state.tasks;
    },
    task: state => {
        return state.task;
    },
    rows: (state) => {
        return state.rows;
    },
    perPage: (state) => {
        return state.perPage;
    },
    meta: (state) => {
        return state.meta;
    },
};

const actions = {
    getTasks({ commit, dispatch }, filter) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(`/admin/tasks?from=admin&page=${filter.currentPage ? filter.currentPage : ''}&status=${filter.status ? filter.status : ''}&account_number=${filter.account_number ? filter.account_number : ''}&mobile_number=${filter.mobile_number ? filter.mobile_number : ''}&uid=${filter.uid ? filter.uid : ''}&sortBy=${filter && filter.sortBy ? filter.sortBy : ''}&sortOrder=${filter && filter.sortOrder ? filter.sortOrder : ''}`)
                .then((res) => {
                    commit('SET_TASKS', res.data.data);
                    commit(
                        "setRows",
                        Math.ceil(res.data.meta.total / res.data.meta.per_page)
                    );
                    commit("setPerPage", parseInt(res.data.meta.per_page));
                    commit('SET_META', res.data.meta)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }
    },
    getTask({ commit, dispatch }, taskId) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(`/admin/tasks/${taskId}?from=admin`)
                .then((res) => {
                    commit('SET_TASK', res.data.data);
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }

    },

};

const mutations = {
    SET_TASKS(state, tasks) {
        state.tasks = tasks;
    },
    SET_TASK(state, task) {
        state.task = task;
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
