<script>
export default {
  name: "TablePagination",
  props: {
    pagination: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      options: [
        {value: 5, label: '5'},
        {value: 10, label: '10'},
        {value: 20, label: '20'},
        {value: 50, label: '50'},
        {value: 100, label: '100'},
        {value: 200, label: '200'},
        {value: 500, label: '500'},
        {value: 1000, label: '1000'}
      ],
      page_size: 10
    }
  },
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <span class="text-muted">Show</span>
      <el-select
          size="mini"
          style="width: 70px"
          v-model="page_size"
          placeholder="Select"
          class="mx-2"
          @change="$emit('pagesizechanged', $event)"
      >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <span class="text-muted">
        Entries | Page {{ pagination.current_page }} of {{ pagination.last_page }} | Total {{ pagination.total }} Entries
      </span>
    </div>
    <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        :page-size="page_size"
        :current-page="pagination.current_page"
        @current-change="$emit('pagechanged', $event)"
    />
  </div>
</template>