import axios from "axios";

const state = {
  events: [],
  rows: 1,
  perPage: 10,
  meta: {}
};

const getters = {
  events: (state) => {
    return state.events;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  meta: (state) => {
    return state.meta;
  }
};

const actions = {
  getEvents({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `/admin/events?page=${filter && filter.currentPage ? filter.currentPage : ""}`
        )
        .then((res) => {
          commit("SET_EVENTS", res.data.data);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  store({ commit }, payload) {
    return axios.post(`/admin/events`, payload).then((res) => {
      commit("ADD_EVENT", res.data.data);
    });
  },

  update({ commit }, payload) {
    return axios.put(`/admin/events/${payload.id}`, payload).then((res) => {
      commit("UPDATE_EVENT", res.data.data);
    });
  },
};

const mutations = {
  SET_EVENTS(state, events) {
    state.events = events;
  },
  ADD_EVENT(state, event) {
    state.events.unshift(event);
  },
  UPDATE_EVENT(state, event) {
    state.events.map((item, index) => {
      if (item.id == event.id) {
        state.events[index] = event;
      }
    });
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
