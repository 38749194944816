import axios from "axios";

const state = () => ({
    readings: [],
    generated_bills: [],
    rows: 1,
    perPage: 10,
    meta: {},
    errorLogs: [],
    errorLog: {},
});

const getters = {
    readings: (state) => {
        return state.readings;
    },
    generated_bills: (state) => {
        return state.generated_bills;
    },
    rows: (state) => {
        return state.rows;
    },
    perPage: (state) => {
        return state.perPage;
    },
    meta: state => {
        return state.meta;
    },
    errorLogs: (state) => {
        return state.errorLogs;
    },
    errorLog: (state) => {
        return state.errorLog;
    },
};

// actions
const actions = {
    getReadings({commit, dispatch}, params) {
        const queryParams = new URLSearchParams(params);

        const queryString = queryParams.toString();
        dispatch("Settings/loading", true, {root: true});
        try {
            axios.get(`/admin/readings?${queryString}`).then((responseData) => {
                dispatch("Settings/loading", false, {root: true});
                if (!responseData.data.status){
                    commit("SET_READINGS", []);
                    commit('SET_META', {})
                    return;
                }
                const {
                    data,
                    links,
                    first_page_url,
                    last_page_url,
                    next_page_url,
                    prev_page_url,
                    path,
                    ...meta
                } = responseData.data.data;
                commit("SET_READINGS", data);
                commit('SET_META', meta)
                dispatch("Settings/loading", false, {root: true});
            }).catch((err) => {
                console.log(err);
                dispatch("Settings/loading", false, {root: true});
            });
        } catch (err) {
            console.log(err);
            dispatch("Settings/loading", false, {root: true});
        }
    },
    getGeneratedBills({commit, dispatch}, params) {
        const queryParams = new URLSearchParams(params);

        const queryString = queryParams.toString();
        dispatch("Settings/loading", true, {root: true});
        try {
            axios.get(`/admin/bill-generates?${queryString}`).then((responseData) => {
                dispatch("Settings/loading", false, {root: true});
                if (!responseData.data.status){
                    commit("SET_GENERATED_BILLS", []);
                    commit('SET_META', {})
                    return;
                }
                const {
                    data,
                    links,
                    first_page_url,
                    last_page_url,
                    next_page_url,
                    prev_page_url,
                    path,
                    ...meta
                } = responseData.data.data;
                commit("SET_GENERATED_BILLS", data);
                commit('SET_META', meta)
                dispatch("Settings/loading", false, {root: true});
            }).catch((err) => {
                console.log(err);
                dispatch("Settings/loading", false, {root: true});
            });
        } catch (err) {
            console.log(err);
            dispatch("Settings/loading", false, {root: true});
        }
    },
    store({commit, dispatch}, params) {
        dispatch("Settings/loading", true, {root: true});
        try {
            axios.post(`/admin/readings`, params).then((res) => {
                dispatch("Settings/loading", false, {root: true});
                return res;
            }).catch((err) => {
                console.log(err);
                dispatch("Settings/loading", false, {root: true});
            });
        } catch (err) {
            console.log(err);
            dispatch("Settings/loading", false, {root: true});
        }
    },
};

// mutations
const mutations = {
    SET_READINGS(state, readings) {
        state.readings = readings;
    },
    SET_GENERATED_BILLS(state, generated_bills) {
        state.generated_bills = generated_bills;
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    setErrorLogs(state, errorLogs) {
        state.errorLogs = errorLogs;
    },
    setErrorLog(state, errorLog) {
        state.errorLog = errorLog;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
