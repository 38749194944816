import axios from 'axios';
import { saveAs } from "file-saver";

const state = () => ({
    bills: [],
    arrBills: [],
    rows: 1,
    perPage: 10,
    isLoading: false,
    status: '',
    customer: { first_name: "", last_name: "" },
    weekConsumptions: [],
    pdfFileName: '',
    labelsArray: [],
    chartDataArray: [],
    growthChartData: [],
    growthChartLabel: [],
    wardCustomers: []
})

const getters = {
    bills: state => {
        return state.bills;
    },
    arrBills: state => {
        return state.arrBills;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
    status: state => {
        return state.status;
    },
    customer: state => {
        return state.customer;
    },
    weekConsumptions: state => {
        return state.weekConsumptions;
    },
    isLoading: state => {
        return state.isLoading;
    },
    pdfFileName: state => {
        return state.pdfFileName;
    },
    newChartData: (state) => {
        return state.chartDataArray
    },
    chartLabels: (state) => {
        return state.labelsArray
    },
    growthChartData: (state) => {
        return state.growthChartData
    },
    growthChartLabel: (state) => {
        return state.growthChartLabel
    },
    wardCustomers: (state) => {
        return state.wardCustomers
    }
}

// actions
const actions = {
    getBills({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        var baseUrl;
        if (search && search.bill_type == 'monthly') {
            baseUrl = `/admin/reports/billing?page=${search.currentPage}&month=${search.month}&year=${search.year}&ward_no=${search.ward_no}&bill_type=${search.bill_type}&status=${search.status}`;

        } else if (search && search.bill_type == 'quarterly') {
            baseUrl = `/admin/reports/billing?page=${search.currentPage}&year=${search.year}&quarterNumer=${search.quarterNumer}&ward_no=${search.ward_no}&bill_type=quarterly&status=${search.status}`;
        } else if (search && search.bill_type == 'half_yearly') {
            baseUrl = `/admin/reports/billing?page=${search.currentPage}&year=${search.year}&ward_no=${search.ward_no}&dateRangeNumber=${search.dateRangeNumber}&bill_type=half_yearly&status=${search.status}`;
        } else if (search && search.bill_type == 'yearly') {
            baseUrl = `/admin/reports/billing?page=${search.currentPage}&year=${search.year}&ward_no=${search.ward_no}&bill_type=yearly&status=${search.status}`;
        }

        if (search && search.from && search.to) {
            baseUrl += `&from=${moment(search.from).format(
                "YYYY-MM-D"
            )}&to=${moment(search.to).format("YYYY-MM-D")}`;
        }

        try {
            axios
                .get(baseUrl)
                .then((res) => {
                    commit('setLedgerBills', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    //  false;
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            //  false;
            dispatch('Settings/loading', false, { root: true });
        }

    },

    exportBill({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        var baseUrl;
        if (search && search.bill_type == 'monthly') {
            baseUrl = `/admin/bills/report/export?page=${search.currentPage}&month=${search.month}&year=${search.year}&ward_no=${search.ward_no}&bill_type=${search.bill_type}&status=${search.status}`;

        } else if (search && search.bill_type == 'quarterly') {
            baseUrl = `/admin/bills/report/export?page=${search.currentPage}&year=${search.year}&quarterNumer=${search.quarterNumer}&ward_no=${search.ward_no}&bill_type=quarterly&status=${search.status}`;
        } else if (search && search.bill_type == 'half_yearly') {
            baseUrl = `/admin/bills/report/export?page=${search.currentPage}&year=${search.year}&ward_no=${search.ward_no}&dateRangeNumber=${search.dateRangeNumber}&bill_type=half_yearly&status=${search.status}`;
        } else if (search && search.bill_type == 'yearly') {
            baseUrl = `/admin/bills/report/export?page=${search.currentPage}&year=${search.year}&ward_no=${search.ward_no}&bill_type=yearly&status=${search.status}`;
        }

        if (search && search.from && search.to) {
            baseUrl += `&from=${moment(search.from).format(
                "YYYY-MM-D"
            )}&to=${moment(search.to).format("YYYY-MM-D")}`;
        }
        axios
            .get(baseUrl, { responseType: "arraybuffer", })
            .then((res) => {
                dispatch('Settings/loading', false, { root: true });
                let blob = new Blob([res.data], {
                    type: "application/xlsx;charset=utf-8",
                });
                var number = Math.ceil(Math.random() * 1000);
                var filename = number + "_bills.xlsx";
                saveAs(blob, filename);
            })
            .catch(() => {
                dispatch('Settings/loading', false, { root: true });
                this.$toastr("error", "Something went wrong", "Error!");
            });
    },

    getLedgerBills({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        var baseUrl = `/admin/reports/ledger-billing?page=${search && search.currentPage ? search.currentPage : ''}&fromYear=${search && search.fromYear ? search.fromYear : ''}&toYear=${search && search.toYear ? search.toYear : ''}&account_number=${search && search.account_number ? search.account_number : ''}`;

        try {
            axios
                .get(baseUrl)
                .then((res) => {
                    commit('setLedgerBills', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('setStatus', this.status = 1)
                    let bills = res.data.data;
                    if (bills.length) {
                        var customerId = bills[0].customer_account_id;
                        dispatch('getCustomer', customerId);
                    }
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    //  false;
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            //  false;
            dispatch('Settings/loading', false, { root: true });
        }
    },

    getArrearsBills({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        var baseUrl = `/admin/reports/arrears-billing?page=${search && search.currentPage ? search.currentPage : ''}&fromYear=${search && search.fromYear ? search.fromYear : ''}&toYear=${search && search.toYear ? search.toYear : ''}&account_number=${search && search.account_number ? search.account_number : ''}`;

        try {
            axios
                .get(baseUrl)
                .then((res) => {
                    commit('setArrearsBills', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('setStatus', this.status = 2)
                    let bills = res.data.data;
                    if (bills.length) {
                        var customerId = bills[0].customer_account_id;
                        dispatch('getCustomer', customerId);
                    }
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    //  false;
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            //  false;
            dispatch('Settings/loading', false, { root: true });
        }
    },

    getCustomer({ commit, dispatch }, customerId) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(`/admin/customer-accounts/${customerId}`)
                .then((res) => {
                    commit('setCustomer', res.data.data)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }

    },

    downloadBill({ commit, dispatch }, bill) {
        dispatch('Settings/loading', true, { root: true });
        axios
            .get(`/admin/bills/${bill.id}/download`, {
                responseType: "arraybuffer",
            })
            .then((res) => {
                dispatch('Settings/loading', false, { root: true });
                let blob = new Blob([res.data], {
                    type: "application/pdf;charset=utf-8",
                });
                var number = Math.ceil(Math.random() * 1000);
                var filename = number + "_bill.pdf";
                saveAs(blob, filename);
            })
            .catch(() => {
                dispatch('Settings/loading', false, { root: true });
                this.$toastr("error", "Something went wrong", "Error!");
            });
    },
    // downloadLedgerBillRport({ commit, dispatch }, search) {
    //       dispatch('Settings/loading', true, { root: true });
    //     console.log('ss', this.bills)
    //     if (this.bills.length) {
    //         var customerId = this.bills[0].customer_id;
    //         dispatch('getCustomer', customerId);
    //     }
    //     axios
    //         .get(
    //             `/admin/ledger-reports/download?id=${customerId}&fromYear=${search.fromYear}&toYear=${search.toYear}&account_number=${search.account_number}`,
    //             {
    //                 responseType: "arraybuffer",
    //             }
    //         )
    //         .then((res) => {
    //             commit('setLoading',  false)
    //             let blob = new Blob([res.data], {
    //                 type: "application/pdf;charset=utf-8",
    //             });
    //             var number = Math.ceil(Math.random() * 1000);
    //             var filename = number + "_bill.pdf";
    //             saveAs(blob, filename);
    //         })
    //         .catch(() => {
    //             commit('setLoading',  false)
    //             this.$toastr("error", "Something went wrong", "Error!");
    //         });
    // },
    billingAnalytics({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(
                    `/admin/bills/billingAnalytics?year=${search && search.year ? search.year : ''}&month=${search && search.month ? search.month : ''}&view=${search.view}`
                )
                .then((res) => {
                    commit("setWeekConsumptions", res.data)
                    dispatch("setChartData")
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }

    },

    growthReport({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(
                    `/admin/reports/customer-growth?year=${search && search.year ? search.year : ''}&type=${search.type}`
                )
                .then((res) => {
                    var labelArray = [];
                    var chartDataArray = [];
                    res.data.data.forEach((element) => {
                        if(search.type === 'YEARLY') {
                            labelArray.push(element.year);
                        } else {
                            labelArray.push(element.month+', '+ element.year);
                        }
                        chartDataArray.push(element.customer_count);
                    });

                    var cArr = [
                        {
                            label: "Customers Growth",
                            backgroundColor: 'transparent',
                            borderColor: '#80c5de',
                            data: chartDataArray,
                            categoryPercentage: 0.4,
                            barPercentage: 1,
                        },
                    ];

                    commit("SET_GROWTH_CHART_DATA", {labelArray, cArr})
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }

    },

    wardWiseReport({ commit, dispatch }) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(
                    `/admin/reports/ward-customers`
                )
                .then((res) => {
                    commit("SET_WARD_CUSTOMER_DATA", res.data)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }

    },

    exportWardReport({ dispatch }) {
        dispatch('Settings/loading', true, { root: true });
        axios
            .get(`/admin/reports/ward-customers/export`, { responseType: "arraybuffer", })
            .then((res) => {
                dispatch('Settings/loading', false, { root: true });
                let blob = new Blob([res.data], {
                    type: "application/xlsx;charset=utf-8",
                });
                var number = Math.ceil(Math.random() * 1000);
                var filename = number + "_ward_customers.xlsx";
                saveAs(blob, filename);
            })
            .catch(() => {
                dispatch('Settings/loading', false, { root: true });
                this.$toastr("error", "Something went wrong", "Error!");
            });
    },

    setChartData({ commit, state }) {
        var labelArray = [];
        var chartDataArray = [];
        state.weekConsumptions.forEach((element) => {
            labelArray.push(element.week);
            chartDataArray.push(element.total_unit);
        });

        // chartDataArray = _.map(chartDataArray, function (item, i) {
        //     return chartDataArray[i + 1] ? (chartDataArray[i + 1] - item).toFixed(2) : 0;
        // });
        // labelArray.shift();
        // chartDataArray.pop();

        var cArr = [
            {
                label: "Consumption",
                backgroundColor: "#3D5B96",
                data: chartDataArray,
                categoryPercentage: 0.4,
                barPercentage: 1,
            },
        ];
        commit('SET_LABELS', labelArray);
        commit('SET_BAR_CHART_DATA', cArr);
    },

    resetBills({ commit }) {
        commit('setBills', []);
    }

}

// mutations
const mutations = {
    setBills(state, bills) {
        state.bills = bills;
    },
    setLedgerBills(state, bills) {
        state.bills = bills;
    },
    setArrearsBills(state, arrBills) {
        state.arrBills = arrBills;
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
    setStatus(state, status) {
        state.status = status;
    },
    setCustomer(state, customer) {
        state.customer = customer;
    },
    setWeekConsumptions(state, weekConsumptions) {
        state.weekConsumptions = weekConsumptions;
    },
    SET_LABELS(state, labels) {
        state.labelsArray = labels;
    },
    SET_BAR_CHART_DATA(state, data) {
        state.chartDataArray = data;
    },
    SET_GROWTH_CHART_DATA(state, {labelArray, cArr}) {
        state.growthChartLabel = labelArray;
        state.growthChartData = cArr;
    },
    SET_WARD_CUSTOMER_DATA(state, data) {
        state.wardCustomers = data
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

