import axios from "axios";

const state = () => ({
  customerAccounts: [],
  customerAccount: { first_name: "", last_name: "" },
  meters: [],
  rows: 1,
  perPage: 10,
  isLoading: false,
  customer: {},
});

const getters = {
  customerAccounts(state, getters, rootState, rootGetters) {
    return state.customerAccounts;
  },
  customerAccount: (state) => {
    return state.customerAccount;
  },
  meters: (state) => {
    return state.meters;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  customer: (state) => {
    return state.customer;
  },
};

// actions
const actions = {
  getCustomerAccounts({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/admin/customer-accounts?name=${
            filter && filter.name ? filter.name : ""
          }&account_number=${
            filter && filter.account_number ? filter.account_number : ""
          }&ward_number=${
            filter && filter.ward_number ? filter.ward_number : ""
          }&mobile_number=${
            filter && filter.mobile_number ? filter.mobile_number : ""
          }&customer_id=${
            filter && filter.customer_id ? filter.customer_id : ""
          }&page=${filter && filter.currentPage ? filter.currentPage : ""}`
        )
        .then((res) => {
          commit("SET_CUSTOMER_ACC", res.data.data);
          commit(
            "SET_ROWS",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("SET_PER_PAGE", parseInt(res.data.meta.per_page));
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  store({ commit }, data) {
    return axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/admin/customer-accounts`, data)
        .then((res) => {
          if (data.customer_id){
            commit("ADD_CUSTOMER_ACC", res.data.data);
          }
        });
  },

  update({ commit }, data) {
    return axios
      .put(
        `${process.env.VUE_APP_API_BASE_URL}/admin/customer-accounts/${data.id}`,
        data
      )
      .then((res) => {
        commit("UPDATE_ACC", res.data.data);
      });
  },
  assignTeamToCustomer({ commit }, { team, customerId }) {
    return axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/admin/customer-accounts/${customerId}/assign-teams`,
        team
      )
      .then((res) => {
        commit("SET_TASK_ASSIGNED", res.data.data);
      });
  },

  getCustomerById({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(`/admin/customers/${id}`)
        .then((res) => {
          commit("setCustomerById", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },

  customerAccountDetails({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });
    commit('RESET_CUSTOMER');
    try {
      return axios
        .get(`/admin/customer-accounts/${id}`)
        .then((res) => {
          commit("CUSTOMER_ACC_DETAILS", res.data.data);
          dispatch("Settings/loading", false, { root: true });
          return res;
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
    }
  },

  getMetersByCustomerId({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`/admin/customers/${id}/meters`)
        .then((res) => {
          commit("setMetersByCustomerId", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },
};

// mutations
const mutations = {
  SET_CUSTOMER_ACC(state, customerAccounts) {
    state.customerAccounts = customerAccounts;
  },
  ADD_CUSTOMER_ACC(state, data) {
    state.customerAccounts.unshift(data);
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_PER_PAGE(state, perPage) {
    state.perPage = perPage;
  },
  setCustomerById(state, customer) {
    state.customer = customer;
  },
  setMetersByCustomerId(state, meters) {
    state.meters = meters;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  CUSTOMER_ACC_DETAILS(state, customer) {
    state.customer = customer;
  },
  RESET_CUSTOMER(state) {
    state.customer = {};
  },
  UPDATE_ACC(state, customerAccount) {
    state.customerAccounts.map((item, index) => {
      if (item.id == customerAccount.id) {
        state.customerAccounts[index] = customerAccount;
      }
    });
  },
  SET_TASK_ASSIGNED(state, customerAccount) {
    state.customerAccounts.map((item, index) => {
      if (item.id == customerAccount.id) {
        state.customerAccounts[index]['hasTask'] = true;
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
