import axios from 'axios';

const state = {
    permissions: [],
};

const getters = {
    permissions: state =>{
        return state.permissions;
    },
};

const actions = {
    getPermissions({commit, dispatch}){
        try {
            axios
                .get(`/admin/permissions`)
                .then((res) => {
                    commit('setPermissions', res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }

    }
};

const mutations = {
    setPermissions(state, permissions){
        state.permissions = permissions;
    },
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}