// Initial state set here
const getDefaultState = () => {
  return {    
    breadcrumbs: [{text:"Test", path:"/test", isActive: false}, {text:"Edit", path:"/test/2", isActive: true}] 
  }
}

const Config = {
    namespaced: true,

    state: getDefaultState(),

    getters: {      
      breadcrumbs(state) {
        return state.breadcrumbs
      }
    },
    mutations: {          
      SET_BREADCRUMB(state, data) {
        state.breadcrumbs = data;        
      },  
      RESET_STATE (state) {
        Object.assign(state, getDefaultState())
      }     
    },
    actions: {          
      setBreadCrumb({commit}, data) {
        commit("SET_BREADCRUMB", data)
      } 
    },
  };
 

export default Config
