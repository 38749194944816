import axios from 'axios';
import { saveAs } from "file-saver";

const state = () => ({
    topDueBills: [],
    downloadBillLists: [],
    bills: [],
    bill: [],
    rows: 1,
    perPage: 10
})

const getters = {
    topDueBills: state => {
        return state.topDueBills;
    },
    bills: state => {
        return state.bills;
    },
    bill: state => {
        return state.bill;
    },
    downloadBillLists: state => {
        return state.downloadBillLists;
    },
    rows: state => {
        return state.rows;
    },
    perPage: state => {
        return state.perPage;
    },
}

// actions
const actions = {
    getBills({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(
                    `/admin/bills?page=${search && search.currentPage ? search.currentPage : ''}`
                )
                .then((res) => {
                    commit('setbills', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });

                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });

        }
    },
    getBill({ commit }, id) {
        try {
            return axios.get(`/admin/fetch-bill/${id}`).then((res) => {
                commit("SET_BILL", id);
                return res;
            });
        } catch (err) {
            console.log(err);
        }
    },
    getTopDueBills({ commit, dispatch }) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(`admin/bills/top-due`)
                .then((res) => {
                    commit('setTopDueBills', res.data.data)
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }
    },

    searchBilling({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        try {
            let queryParams = `cname=${search.customer_name}&meterno=${search.meter_serial_no}&month=${search.month}&year=${search.year}&status=${search.status}&bill_type=${search.bill_type}`;

            axios
                .get(`/admin/bills/search?${queryParams}&page=${search.currentPage}`)
                .then((res) => {
                    commit('setbills', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });

                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }
    },
    downloadBill({ dispatch }, bill) {
        dispatch('Settings/loading', true, { root: true });
        axios
            .get(`/admin/bills/${bill.id}/download`, {
                responseType: "arraybuffer",
            })
            .then((res) => {

                let blob = new Blob([res.data], {
                    type: "application/pdf;charset=utf-8",
                });
                //var number = Math.ceil(Math.random() * 1000);
                var number = bill.bill_number;
                var filename = number + "_bill.pdf";
                saveAs(blob, filename);
                dispatch('Settings/loading', false, { root: true });
            })
            .catch(() => {
                // this.$toastr("error", "Something went wrong", "Error!");
                dispatch('Settings/loading', false, { root: true });
            });
    },

    downloadMultipleBill({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        let queryParams = `bill_month=${search.bill_month}&bill_year=${search.bill_year}&status=${search.status}`;
        return axios
            .get(`/admin/bills/multiple/download?${queryParams}`)
            .then((res) => {
                commit("ADD_DOWNLOAD_BILLS", res.data.data);
                // let blob = new Blob([res.data], {
                //     type: "application/pdf;charset=utf-8",
                // });
                // var number = Math.ceil(Math.random() * 100000);
                // var filename = number + "_bills.pdf";
                // saveAs(blob, filename);
                dispatch('Settings/loading', false, { root: true });
                return res;
            });
    },

    downloadMultipleFileLists({ commit, dispatch }, search) {
        dispatch('Settings/loading', true, { root: true });
        let queryParams = `bill_month=${search.bill_month}&bill_year=${search.bill_year}&status=${search.status}`;
        return axios
            .get(`/admin/bills/download-multiple-files-lists?${queryParams}`)
            .then((res) => {
                commit("ADD_DOWNLOAD_MULTIPLE_FILES", res.data.data);
                dispatch('Settings/loading', false, { root: true });
                return res;
            });
    },


    store({commit}, data) {
        return axios.post(`${process.env.VUE_APP_API_BASE_URL}/admin/bills`, data)
            .then((res) => {
                commit("ADD_BILL", res.data.data);
                return res;
            });
    },

    update({commit}, data) {
        return axios.put(`${process.env.VUE_APP_API_BASE_URL}/admin/bills/${data.id}`, data)
            .then((res) => {
                commit("ADD_BILL", res.data.data);
                return res;
            });
    },
    import({ commit }, formData, headers) {
        return axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/admin/bills/import`, formData, { headers })
            .then((res) => {});
    },
}

// mutations
const mutations = {
    setTopDueBills(state, topDueBills) {
        state.topDueBills = topDueBills;
    },
    setbills(state, bills) {
        state.bills = bills;
    },
    SET_BILL(state, bill) {
        state.bill = bill;
    },
    ADD_DOWNLOAD_BILLS(state, data) {
        state.downloadBillLists.unshift(data);
    },
    ADD_DOWNLOAD_MULTIPLE_FILES(state, bills) {
        state.downloadBillLists= bills;
    },
    ADD_BILL(state, data) {
        state.bills.unshift(data);
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

