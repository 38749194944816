import axios from "axios";

const state = () => ({
  latestMeters: [],
  meters: [],
  meter: {},
  meterConsumer: {},
  consumptions: [],
  consumptionObj: {},
  rows: 1,
  perPage: 10,
  meta: {},
  errorLogs: [],
  errorLog: {},
});

const getters = {
  latestMeters: (state) => {
    return state.latestMeters;
  },
  meters: (state) => {
    return state.meters;
  },
  rows: (state) => {
    return state.rows;
  },
  perPage: (state) => {
    return state.perPage;
  },
  meta: state => {
    return state.meta;
  },
  consumptions: (state) => {
    return state.consumptions;
  },
  consumptionObj: (state) => {
    return state.consumptionObj;
  },
  errorLogs: (state) => {
    return state.errorLogs;
  },
  errorLog: (state) => {
    return state.errorLog;
  },
  meter: (state) => {
    return state.meter;
  },
  meterConsumer: (state) => {
    return state.meterConsumer;
  },
};

// actions
const actions = {
  getMeters({ commit, dispatch }, filter) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `/admin/meters?column=${
            filter && filter.column ? filter.column : ""
          }&keyword=${
            filter && filter.keyword ? filter.keyword : ""
          }&page=${
            filter && filter.currentPage ? filter.currentPage : ""
          }&show=${filter && filter.show ? filter.show : ""}&sortBy=${filter && filter.sortBy ? filter.sortBy : ''}&sortOrder=${filter && filter.sortOrder ? filter.sortOrder : ''}`
        )
        .then((res) => {
          commit("setMeters", res.data.data);
          commit(
            "setRows",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("setPerPage", parseInt(res.data.meta.per_page));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getLatestMeters({ commit, dispatch }) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`admin/meters?limit=5`)
        .then((res) => {
          commit("setlatestMeters", res.data.data);
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getmeterById({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`/admin/meters/${id}`)
        .then((res) => {
          commit("setmeterById", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getMeterAndConsumerByMeterId({ commit, dispatch }, id) {
    dispatch("Settings/loading", true, { root: true });

    try {
      axios
        .get(`/admin/meter-consumer/${id}`)
        .then((res) => {
          commit("METER_CONSUMER", res.data.data);
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getMeterConsumptions({ commit, dispatch }, params) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `/admin/meters/${
            params && params.meterId ? params.meterId : ""
          }/consumptions?page=${
            params && params.currentPage ? params.currentPage : ""
          }&start_date=${
              params && params.start_date ? params.start_date : ""
          }&end_date=${
              params && params.end_date ? params.end_date : ""
          }`
        )
        .then((res) => {
          commit("setMeterConsumptions", res.data.data);
          commit("setConsumptionObj", _.head(res.data.data));
          commit(
            "setRows",
            Math.ceil(res.data.meta.total / res.data.meta.per_page)
          );
          commit("setPerPage", parseInt(res.data.meta.per_page));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },


  getAllMeterConsumptions({ commit, dispatch }, params) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
          .get(
              `/admin/meters-all-consumptions?page=${
                  params && params.currentPage ? params.currentPage : ""
              }&start_date=${
                  params && params.start_date ? params.start_date : ""
              }&end_date=${
                  params && params.end_date ? params.end_date : ""
              }&month=${
                  params && params.month ? params.month : ""
              }&sortBy=${params && params.sortBy ? params.sortBy : ''}&sortOrder=${params && params.sortOrder ? params.sortOrder : ''}`
          )
          .then((res) => {
            commit("setMeterConsumptions", res.data.data);
            commit("setConsumptionObj", _.head(res.data.data));
            commit(
                "setRows",
                Math.ceil(res.data.meta.total / res.data.meta.per_page)
            );
            commit("setPerPage", parseInt(res.data.meta.per_page));
            commit('SET_META', res.data.meta)
            dispatch("Settings/loading", false, { root: true });
          })
          .catch((err) => {
            console.log(err);
            dispatch("Settings/loading", false, { root: true });
          });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getTblReadingView({ commit, dispatch }, params) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
          .get(
              `/admin/meters-transfer-consumptions?page=${
                  params && params.currentPage ? params.currentPage : ""
              }&start_date=${
                  params && params.start_date ? params.start_date : ""
              }&end_date=${
                  params && params.end_date ? params.end_date : ""
              }&month=${
                  params && params.month ? params.month : ""
              }`
          )
          .then((res) => {
            dispatch("Settings/loading", false, { root: true });
            commit("setMeterConsumptions", res.data.data);
            commit(
                "setRows",
                Math.ceil(res.data.meta.total / res.data.meta.per_page)
            );
            commit("setPerPage", parseInt(res.data.meta.per_page));
            dispatch("Settings/loading", false, { root: true });
          })
          .catch((err) => {
            console.log(err);
            dispatch("Settings/loading", false, { root: true });
          });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  transferReadingToMSSQL({ commit, dispatch }, params) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
          .get(
              `/admin/meters-transfer-consumptions?page=${
                  params && params.currentPage ? params.currentPage : ""
              }&start_date=${
                  params && params.start_date ? params.start_date : ""
              }&end_date=${
                  params && params.end_date ? params.end_date : ""
              }&month=${
                  params && params.month ? params.month : ""
              }`
          )
          .then((res) => {
            dispatch("Settings/loading", false, { root: true });
            return res;
          })
          .catch((err) => {
            console.log(err);
            dispatch("Settings/loading", false, { root: true });
          });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getErrorLogs({ commit, dispatch }, params) {
    dispatch("Settings/loading", true, { root: true });
    try {
      axios
        .get(
          `/admin/meters/${
            params && params.meterId ? params.meterId : ""
          }/error-logs?type=${params && params.type ? params.type : ""}`
        )
        .then((res) => {
          commit("setErrorLogs", res.data.data);
          commit("setErrorLog", _.head(res.data.data));
          commit('SET_META', res.data.meta)
          dispatch("Settings/loading", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          dispatch("Settings/loading", false, { root: true });
        });
    } catch (err) {
      console.log(err);
      dispatch("Settings/loading", false, { root: true });
    }
  },

  getAllErrorLogs({ commit, dispatch }, params) {
    dispatch("Settings/loading", true, { root: true });
    axios
      .get(
        `/admin/error-logs?type=${
          params && params.type ? params.type : ""
        }&hardware_serial_no=${
          params && params.hardware_serial_no ? params.hardware_serial_no : ""
        }&project_id=${
          params && params.project_id ? params.project_id : ""
        }&status=${params && params.status ? params.status : ""}&page=${
          params && params.currentPage ? params.currentPage : ""
        }&sortBy=${params && params.sortBy ? params.sortBy : ''}&sortOrder=${params && params.sortOrder ? params.sortOrder : ''}`
      )
      .then((res) => {
        commit("setErrorLogs", res.data.data);
        commit(
          "setRows",
          Math.ceil(res.data.meta.total / res.data.meta.per_page)
        );
        commit("setPerPage", parseInt(res.data.meta.per_page));
        commit('SET_META', res.data.meta)
        dispatch("Settings/loading", false, { root: true });
      })
      .catch(() => {
        dispatch("Settings/loading", false, { root: true });
      });
  },

  updateMeterErrorStatus({ commit }, { errorId, status }) {
    return axios
      .put(`/admin/meters/${errorId}/resolved-error`, { status: status })
      .then((res) => {
        commit("UPDATE_ERROR_LOG_STATUS", res.data);
        return res;
      });
  },

  updateMeterStatus({ commit }, { meterId, status }) {
    return axios
      .put(`/admin/meters/${meterId}/status`, { status: status })
      .then((res) => {
        commit("UPDATE_METER", res.data.data);
        return res;
      });
  },

  store({ commit }, data) {
    return axios
      .post(`${process.env.VUE_APP_API_BASE_URL}/admin/meters`, data)
      .then((res) => {
        commit("ADD_METER", res.data.data);
      });
  },
  update({ commit }, data) {
    return axios
      .put(`${process.env.VUE_APP_API_BASE_URL}/admin/meters/${data.id}`, data)
      .then((res) => {
        commit("UPDATE_METER", res.data.data);
      });
  },

  import({ commit }, formData, headers) {
    return axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/admin/meters/import`, formData, { headers })
        .then((res) => {});
  },
};

// mutations
const mutations = {
  setlatestMeters(state, latestMeters) {
    state.latestMeters = latestMeters;
  },

  setMeters(state, meters) {
    state.meters = meters;
  },
  setMeterConsumptions(state, consumptions) {
    state.consumptions = consumptions;
  },
  setConsumptionObj(state, consumptionObj) {
    state.consumptionObj = consumptionObj;
  },
  setRows(state, rows) {
    state.rows = rows;
  },
  setPerPage(state, perPage) {
    state.perPage = perPage;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  setErrorLogs(state, errorLogs) {
    state.errorLogs = errorLogs;
  },
  setErrorLog(state, errorLog) {
    state.errorLog = errorLog;
  },
  setmeterById(state, meter) {
    state.meter = meter;
  },
  METER_CONSUMER(state, meterConsumer) {
    state.meterConsumer = meterConsumer;
  },
  ADD_METER(state, meter) {
    state.meters.unshift(meter);
  },
  UPDATE_METER(state, meter) {
    // state.meters.unshift(meter);
    // state.meters.map((item, index) => {
    //   if (item.id == meter.id) {
    //     state.meters[index] = meter;
    //   }
    // });

    var index = state.meters.findIndex((item) => {
      return item.id === meter.id;
    });
    state.meters[index] = meter;
  },
  UPDATE_ERROR_LOG_STATUS(state, errorLog) {
    var index = state.errorLogs.findIndex((item) => {
      return item.id === errorLog.id;
    });
    state.errorLogs[index].status = errorLog.status;
    state.errorLogs[index].solved_at = errorLog.solved_at;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
