import bearer from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'
// Auth base configuration some of this options
// can be override in method calls
const config = {
    auth: bearer,
    http: axios,
    router: router,
    tokenDefaultName: 'access-token',
    tokenStore: ['localStorage'],
    rolesVar: 'role',
    //registerData: {url: 'auth/register', method: 'POST', redirect: '/login'},
    // loginData: {url: 'vendor/login', method: 'POST', redirect: '/onboarding-screen', fetchUser: true},
    loginData: {url: 'auth/login', method: 'POST', redirect: '/dashboard', fetchUser: true},
    logoutData: {url: 'auth/logout', method: 'GET', redirect: '/login', makeRequest: true},
    fetchData: {url: 'auth/me?from=admin', method: 'GET', enabled: true},
    refreshData: {url: 'auth/refresh', method: 'GET', enabled: true, interval: 30},
    authRedirect: {path: '/login'},
    forbiddenRedirect: {path: '/pages/500'},
    //notFoundRedirect: {path: '/login'},
    parseUserData: (data) => {
        return data.data  || {};
    },
}
export default config
