import axios from 'axios';

const state = () => ({
    customers: [],
    latestCustomers: [],
    customer: { first_name: '', last_name: '' },
    meters: [],
    rows: 1,
    meta: {},
    perPage: 10,
    isLoading: false,

})

const getters = {
    customers: state => {
        return state.customers;
    },
    latestCustomers: state => {
        return state.latestCustomers;
    },
    customer: state => {
        return state.customer;
    },
    meters: state => {
        return state.meters;
    },
    rows: state => {
        return state.rows;
    },
    meta: state => {
        return state.meta;
    },
    perPage: state => {
        return state.perPage;
    },
    isLoading: state => {
        return state.isLoading;
    }
}

// actions
const actions = {
    import({ commit }, formData, headers) {
        return axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/admin/customers/import`, formData, { headers })
            .then((res) => {});
    },
    getCustomers({ commit }, filter) {
        return axios
                .get(
                    `${process.env.VUE_APP_API_BASE_URL}/admin/customers?column=${filter && filter.column ? filter.column : ''}&keyword=${filter && filter.keyword ? filter.keyword : ''}&page=${filter && filter.currentPage ? filter.currentPage : ''}`
                )
                .then((res) => {
                    commit('setCustomers', res.data.data)
                    commit('setRows', Math.ceil(
                        res.data.meta.total / res.data.meta.per_page
                    ))
                    commit('setPerPage', parseInt(res.data.meta.per_page))
                    commit('setMeta', res.data.meta)
                    return res;
                })
                .catch((err) => {
                    console.log(err);
                });

    },

    getLatestCustomers({ commit }) {
        return axios
                .get(
                    `${process.env.VUE_APP_API_BASE_URL}/admin/get-customers?limit=5`
                )
                .then((res) => {
                    commit('setLatestCustomers', res.data.data)
                    return res;
                })
                .catch((err) => {
                    console.log(err);
                });

    },

    store({ commit }, data) {
        return axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/admin/customers`, data)
            .then((res) => {
                //commit("ADD_CUSTOMER", res.data.data);
            });
    },

    getCustomerById({ commit, dispatch }, id) {
        dispatch('Settings/loading', true, { root: true });
        try {
            axios
                .get(`/admin/customers/${id}`)
                .then((res) => {
                    commit('setCustomerById', res.data.data)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
        }
    },

    getMetersByCustomerId({ commit, dispatch }, id) {
        dispatch('Settings/loading', true, { root: true });

        try {
            axios
                .get(`/admin/customers/${id}/meters`)
                .then((res) => {
                    commit('setMetersByCustomerId', res.data.data)
                    dispatch('Settings/loading', false, { root: true });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch('Settings/loading', false, { root: true });
                });
        } catch (err) {
            console.log(err);
            dispatch('Settings/loading', false, { root: true });
        }
    }



}

// mutations
const mutations = {
    setCustomers(state, customers) {
        state.customers = customers;
    },
    setLatestCustomers(state, customers) {
        state.latestCustomers = customers;
    },
    ADD_CUSTOMER(state, data) {
        state.customers.unshift(data);
    },
    setRows(state, rows) {
        state.rows = rows;
    },
    setPerPage(state, perPage) {
        state.perPage = perPage;
    },
    setMeta(state, meta) {
        state.meta = meta;
    },
    setCustomerById(state, customer) {
        state.customer = customer;
    },
    setMetersByCustomerId(state, meters) {
        state.meters = meters;
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

