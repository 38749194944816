import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

// var firebaseConfig = {
//     apiKey: "AIzaSyDvS4F_-W5U6Or-7umRFPj8K8luJOEapaE",
//     authDomain: "smart-water-meter-77555.firebaseapp.com",
//     databaseURL: "https://smart-water-meter-77555-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "smart-water-meter-77555",
//     storageBucket: "smart-water-meter-77555.appspot.com",
//     messagingSenderId: "212943778901",
//     appId: "1:212943778901:web:9d31ea7bb57bb6e92850dc",
//     measurementId: "G-3WEF5JJDGY"
// }

const firebaseConfig = {
    apiKey: "AIzaSyBsb-FMmdNjyugXdCY_BsPDu9_9cY9JtH8",
    authDomain: "cwasa-61f4d.firebaseapp.com",
    projectId: "cwasa-61f4d",
    storageBucket: "cwasa-61f4d.appspot.com",
    messagingSenderId: "1094949411583",
    appId: "1:1094949411583:web:647225cf41fc49d1cc8aca",
    measurementId: "G-X3Z6X4E3PL"
  };

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()