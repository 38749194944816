<script>
import axios from "axios";
import moment from "moment";
import TableColumn from "@/components/TableColumn.vue";

export default {
  name: "HistoryTracking",
  components: {TableColumn},
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    model: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: this.$route.params.model ? this.$route.params.model : "All History",
        path: "",
        isActive: true
      }],
      filter: {
        keyword: "",
        currentPage: 1,
        start_date: moment().clone().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        model: "",
        id: "",
        sortBy: "id",
        sortOrder: "desc",
      },
      histories: [],
      modelData: {},
      showModelColumn: false,
      title: 'All History',
      dateError: false,
    };
  },
  mounted() {
    let model = this.$route.params.model ? this.replaceModelName(this.$route.params.model) : this.model;
    let id = this.$route.params.id ? this.$route.params.id : this.id;

    this.filter.model = model === "Consumer" ? "CustomerAccount" : model;
    this.filter.id = id;

    model ? this.showModelColumn = false : this.showModelColumn = true;

    this.title = model ? `${model} History` : 'All History';

    this.getHistory();
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },

  methods: {
    changeSort(event) {
      this.filter.sortBy = event[0];
      this.filter.sortOrder = event[1];
      this.getHistory(this.filter.currentPage, this.filter.keyword);
    },

    filterMeter(search) {

      if (this.filter.start_date > this.filter.end_date) {
        this.dateError = true;
        return;
      }

      this.dateError = false;

      this.getHistory(search.currentPage, search.keyword);
    },
    getHistory($page = 1, $search = "", $model = this.filter.model, $historyable_id = this.filter.id) {
      axios.get(`/admin/history?page=${$page}&search=${$search}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&model=${$model}&historyable_id=${$historyable_id}&sortBy=${this.filter.sortBy}&sortOrder=${this.filter.sortOrder}`)
          .then(response => {
            this.histories = response.data.data;
            this.modelData = response.data.modelData;
          })
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.getHistory(value);
    },
    replaceModelName(model) {
      return model.charAt(0).toUpperCase() + model.slice(1, -1);
    },
  }
}
</script>

<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">{{ title }}</h4>
      </CCol>

      <CCol sm="8"></CCol>
    </CRow>

    <CRow>
      <CCol v-if="modelData.hasOwnProperty('id')" sm="12">
        <CCard>
          <div class="table-responsive">
            <table v-if="filter.model === 'CustomerAccount'" class="table table-sm">
              <tr>
                <th>Account No</th>
                <th>Consumer</th>
                <th>Mobile No</th>
                <th>Ward Number</th>
              </tr>
              <tr>
                <td scope="row">{{ modelData.account_number }}</td>
                <td scope="row">{{ modelData.first_name }}</td>
                <td scope="row">{{ modelData.mobile_number }}</td>
                <td scope="row">{{ modelData.ward_number}}</td>
              </tr>
            </table>
            <table v-if="filter.model === 'Meter'" class="table table-sm">
              <tr>
                <th>Meter No</th>
                <th>Installing Date</th>
                <th>Status</th>
              </tr>
              <tr>
                <td scope="row">{{ modelData.meter_number }}</td>
                <td scope="row">{{ modelData.installing_date | dateFormat }}</td>
                <td scope="row">{{ modelData.status }}</td>
              </tr>
            </table>
          </div>
        </CCard>
      </CCol>

      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="12">
              <form v-on:submit.prevent="filterMeter(filter)">
                <CRow>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="date"
                        v-model="filter.start_date"
                        :max="new Date().toISOString().split('T')[0]"
                    />
                    <p v-if="dateError" class="text-danger">
                      {{ dateError ? 'The start date cannot be greater than the end date' : '' }}</p>
                  </CCol>
                  <CCol sm="3" class="pr-0">
                    <CInput
                        required
                        type="date"
                        v-model="filter.end_date"
                        :max="new Date().toISOString().split('T')[0]"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                        v-model="filter.keyword"
                        placeholder="Search..."
                    />
                  </CCol>
                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                  <CCol sm="2">
                    <router-link class="btn btn-primary float-right" :to="{name:'HistoryCreate'}">
                      Consumer update
                    </router-link>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                  <tr class="text-center">
                    <th scope="col">SL</th>
                    <TableColumn align="center" v-if="showModelColumn" name="View" sort="historyable_type"
                                 :position="filter.sortOrder" :id="filter.sortBy" @sort="changeSort"/>
                    <TableColumn align="center" name="Changed Column" sort="changed_column" :position="filter.sortOrder"
                                 :id="filter.sortBy" @sort="changeSort"/>
                    <TableColumn align="center" name="Old Value" sort="changed_value_from" :position="filter.sortOrder"
                                 :id="filter.sortBy" @sort="changeSort"/>
                    <TableColumn align="center" name="New Value" sort="changed_value_to" :position="filter.sortOrder"
                                 :id="filter.sortBy" @sort="changeSort"/>
                    <TableColumn align="center" name="Modified By" sort="changed_by" :position="filter.sortOrder"
                                 :id="filter.sortBy" @sort="changeSort"/>
                    <TableColumn align="center" name="IP Address" sort="ip_address" :position="filter.sortOrder"
                                 :id="filter.sortBy" @sort="changeSort"/>
                    <TableColumn align="center" name="Modified At" sort="action_date" :position="filter.sortOrder"
                                 :id="filter.sortBy" @sort="changeSort"/>
                  </tr>
                  </thead>
                  <tbody v-if="histories.data && histories.data.length">
                  <tr v-for="(history, index) in histories.data" :key="history.id" class="text-center">
                    <td scope="row">{{ index + histories.from }}</td>
                    <td v-if="showModelColumn" scope="row">
                      <div style="cursor: pointer" @click.prevent="$router.push(`/consumers/${history.historyable_id}`)" v-if="history.historyable_type === 'App\\Models\\CustomerAccount'">View Consumer Details</div>
                      <div style="cursor: pointer" @click.prevent="$router.push(`/meters/${history.historyable_id}`)" v-if="history.historyable_type === 'App\\Models\\Meter'">View Meter Details</div>
                      <div v-if="history.historyable_type !== 'App\\Models\\Meter' && history.historyable_type !== 'App\\Models\\CustomerAccount'">{{ history.historyable_type }}</div>
                    </td>
                    <td scope="row">{{ history.changed_column }}</td>
                    <td scope="row">{{ history.changed_value_from }}</td>
                    <td scope="row">{{ history.changed_value_to }}</td>
                    <td scope="row">{{ history.changed_by.first_name }} {{ history.changed_by.last_name }}</td>
                    <td scope="row">{{ history.ip_address }}</td>
                    <td scope="row">
                      {{ history.action_date && history.action_date | dateFormat }}
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td colspan="6">
                      <h5 class="text-center">Data Not Available.</h5>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
              v-if="histories.data && histories.data.length"
              align="center"
              :pages="histories.last_page"
              :active-page.sync="filter.currentPage"
              @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}

.card {
  border: none;
  padding: 15px 15px 0;
}

tbody:before {
  content: "@";
  display: block;
  /* line-height: 30px; */
  text-indent: -99999px;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }

        td:nth-child(2) {
          color: #5e76e7;
        }

        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
