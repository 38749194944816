
const state = {
    isLoading: false,
};

const getters = {
    isLoading: (state) => state.isLoading,
};

const actions = {
    loading({ commit }, param) {
        commit('SET_IS_LOADING', param);
    },
};

const mutations = {
    SET_IS_LOADING: (state, value) => (state.isLoading = value),
};

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}